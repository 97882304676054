<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-white title mb-3 mb-md-1">
				<b>Crear cuenta</b>
			</div>
			<div class="col-12 text-center d-flex gap-2">
				<p class="text-dark">¿Ya tienes una cuenta?</p>
				<p
					@click="$parent.showForm = 'login'"
					class="cursor-pointer text-primary"
				>
					<b>Iniciar sesión</b>
				</p>
			</div>
			<div class="col-12">
				<InputText
					label="Nickname"
					:error="form.errors.get('name')"
					@change-value="(value) => (form.name = value)"
					:errorClass="'text-white'"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputText
					label="Email"
					:error="form.errors.get('email')"
					@change-value="(value) => (form.email = value)"
					:errorClass="'text-white'"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Contraseña"
					:error="form.errors.get('password')"
					@change-value="(value) => (form.password = value)"
					:errorClass="'text-white'"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Repetir contraseña"
					:error="form.errors.get('password_confirm')"
					:feedback="false"
					@change-value="(value) => (form.password_confirm = value)"
					:errorClass="'text-white'"
				/>
			</div>
			<div class="col-12 mt-4">
				<CheckBox
					label="Acepto los términos y condiciones"
					:error="form.errors.get('terms_and_conditions')"
					@change-value="(value) => (form.terms_and_conditions = value)"
					:binary="true"
					:errorClass="'text-white'"
					url="https://elretodekike.com/politica-de-privacidad/"
				/>
			</div>
			<div class="col-12 d-flex mt-4">
				<Button
					@click="save()"
					label="Crear cuenta"
					class="w-100"
					:disabled="form.busy"
				/>
			</div>
		</div>
	</form>
</template>

<script>
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		data: () => ({
			form: new Form({
				terms_and_conditions: false,
			}),
		}),
		methods: {
			...mapActions(["sendForm"]),
			save() {
				const url = `/register`;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.$parent.showRegisterAlert();

						this.$nextTick(() => {
							this.$parent.showForm = "login";
						});
					} else {
						this.$parent.showError();
					}
				});
			},
		},
	};
</script>
