import Consts from "../consts";

const defaults = [
	"roles",
	"permissions",
	"users",
	"challenges",
	"categories",
	"hints",
	"ads",
	"orders",
	"logs",
	"buy_hints",
	"use_hints",
	"ranking",
];

const state = {
	baseURL: Consts.defaultApiRoute,
	showSidebar: false,
	userLogged: null,
	showUpdatePassword: false,
	updatePasswordDialog: false,

	// Datatable defaults
	datatableDefaults: {
		noResults: "No hay resultados",
		lazy: true,
		loading: true,
		paginator: true,
		rows: 5,
		rowsPerPageOptions: [5, 10, 20],
		sortField: "created_at",
		sortOrder: "desc",
		rowHover: true,
		scrollable: true,
		scrollHeight: "600px",
		currentPage: 0,
		paginatorTemplate:
			"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
		currentPageReportTemplate:
			"Mostrando del {first} al {last} de un total de {totalRecords} registros",
		responsiveLayout: "stack",
		breakpoint: "960px",
	},

	// Dialogs defaults
	dialogDefaults: {
		modal: true,
		closeOnEscape: true,
		dismissableMask: true,
	},

	// Web
	// Profile 
	profileStats: {
		userLevel: null,
		userHasChallenges: null,
		challengesSolved: null,
		challengesStarted: null,
		myRank: null,
	},
	pointsHistoric: null,

	// Challenges list
	challengesList: [],
	challengeDetails: null,

	// Challenge details
	allHintsSolved: false,
	hintsPrice: 0,
	challenges_error: {
		dialog: false,
	},
	challenges_success: {
		dialog: false,
	},
	challenges_quit: {
		dialog: false,
	},
	challenges_image: {
		dialog: false,
	},

	// Info
	info: {
		dialog: false,
	}
};

for (const item of defaults) {
	state[item] = {
		// Table
		list: [],
		listTotal: 0,
		currentPage: 0,
		rows: 5,
		sort: {
			field: "id",
			order: -1,
		},

		// Form
		register: null,
		dialog: false,
		dialogMode: "show",
	};
}

state.users.infoDialog = null;
state.challenges.showHints = false;
state.buy_hints.quantity = 0;
state.buy_hints.orderHash = null;

// Ranking
state.ranking.topThree = null;

state.challenges.levels = [
	{
		name: "Fácil",
		value: "easy",
	},
	{
		name: "Medio",
		value: "medium",
	},
	{
		name: "Difícil",
		value: "hard",
	},
];

export default state;
