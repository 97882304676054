<template>
	<div class="sidebar" :class="[showSidebar ? 'show' : '']">
		<div @click="toggleSidebar(false)" class="overlay"></div>
		<div class="sidebar-content text-white">
			<div
				class="h-100 d-flex flex-column align-items-center justify-content-between p-3"
			>
				<div class="w-100">
					<div class="w-100 d-flex justify-content-end">
						<div
							class="info d-flex align-items-center justify-content-center border-radius bg-white"
							@click="toggleSidebar(false)"
						>
							<i
								class="pi pi-times text-secondary"
								style="font-size: 1.2rem"
							></i>
						</div>
					</div>
					<div
						v-if="userLogged"
						class="w-100 bg-secondary border-radius box-shadow p-3 d-flex flex-column mt-3"
					>
						<CurrentUser @click="goProfile" />
						<div class="d-flex flex-column w-100">
							<div
								class="d-flex align-items-center gap-3 border-radius text-white mt-3 small"
							>
								<img src="@/assets/images/web/hints.png" width="20" />
								<span>Pistas restantes: {{ userLogged.hints }}</span>
							</div>
							<div
								class="d-flex align-items-center gap-3 border-radius text-white mt-3 small"
							>
								<img src="@/assets/images/web/level.png" width="20" />
								<span
									>Nivel actual:
									{{
										userLogged.user_level != null
											? userLogged.user_level.level
											: 0
									}}</span
								>
							</div>
							<Button
								label="Comprar pistas"
								class="bg-white text-primary mt-3 small"
								@click="
									toggleFormDialog({
										stateVariable: 'buy_hints',
										show: true,
									})
								"
							/>
						</div>
					</div>
					<div
						class="d-flex"
						:class="[
							userLogged ? 'justify-content-center' : 'justify-content-end',
						]"
					>
						<div
							class="info d-flex align-items-center justify-content-center border-radius mt-3 bg-white"
							@click="
								toggleFormDialog({
									stateVariable: 'info',
									show: true,
								})
							"
						>
							<i
								class="pi pi-info-circle text-secondary"
								style="font-size: 1.2rem"
							></i>
						</div>
					</div>
				</div>
				<div
					v-if="userLogged"
					class="logo d-flex flex-column align-items-center justify-content-center"
				>
					<!-- <router-link to="/">
						<img src="@/assets/images/black-logo.png" alt="Logo" width="100" />
					</router-link> -->
					<Button
						class="bg-white text-primary mt-3 w-100"
						label="Cambiar contraseña"
						@click="toggleUpdatePasswordDialog(true)"
					/>
					<Logout :isMobile="true" class="mt-2 w-100" />
				</div>
				<Button
					v-else
					@click="$router.push({ name: 'login' })"
					icon="pi pi-sign-in"
					label="Iniciar sesión"
					iconPos="right"
					class="bg-white text-primary"
				></Button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapMutations } from "vuex";
	import CurrentUser from "@/components/web/partials/CurrentUser.vue";
	import Logout from "@/components/panels/Logout.vue";

	export default {
		components: {
			CurrentUser,
			Logout,
		},
		data() {
			return {
				show: false,
				links: ["Sobre mí", "Experiencia", "Contacto"],
			};
		},
		methods: {
			...mapMutations([
				"toggleSidebar",
				"toggleFormDialog",
				"toggleUpdatePasswordDialog",
			]),
			goProfile() {
				this.toggleSidebar(false);
				this.$router.push({ name: "Perfil" });
			},
		},
		computed: {
			...mapState(["showSidebar", "userLogged"]),
		},
	};
</script>

<style lang="scss" scoped>
	.sidebar {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 30;
		visibility: hidden;
		touch-action: none;

		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.5);
			opacity: 0;
			transition: $transition;
		}

		.sidebar-content {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			max-width: 90vw;
			transform: translateX(100%);
			transition: $transition;
			background: linear-gradient(180deg, #1e0969 -8.93%, #9747ff 102.98%);

			.info {
				width: 40px;
				height: 40px;
				border: 2px solid white;
			}
		}

		&.show {
			visibility: visible;

			.overlay {
				opacity: 1;
			}

			.sidebar-content {
				transform: translateX(0);
			}
		}
	}
</style>
