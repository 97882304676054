<template>
	<Chart
		ref="Chart"
		id="chart"
		type="line"
		:data="chartData"
		:options="chartOptions"
	/>
</template>

<script>
	import Chart from "primevue/chart";
	import "chartjs-adapter-moment";
	import { mapState } from "vuex";

	const blue = "14, 156, 255";
	const yellow = "255, 167, 38";

	export default {
		components: {
			Chart,
		},
		props: {
			pointsHistoric: {
				type: Array,
				default: () => [],
			},
			filter: {
				type: String,
				default: "24 H",
			},
		},
		data: () => ({
			chartData: {
				// labels: [],
				datasets: [
					{
						label: "Puntos conseguidos",
						// data: [{x: 10, y: 20}, {x: 15, y: null}, {x: 20, y: 10}],
						// data: [
						// 	{ x: "2023-07-10 08:05:00", y: 5 },
						// 	{ x: "2023-07-10 10:07:00", y: 10 },
						// 	{ x: "2023-07-10 12:13:00", y: 20 },
						// 	{ x: "2023-07-10 12:22:58", y: 10 },
						// ],
						fill: true,
						borderColor: `rgb(${blue})`,
						tension: 0.4,
						pointRadius: 3,
						pointHoverRadius: 5,
						pointBackgroundColor: `rgb(${blue})`,
					},
					{
						label: "Pistas utilizadas",
						// data: [],
						borderColor: `rgb(${yellow})`,
						tension: 0.4,
						fill: true,
						pointRadius: 3,
						pointHoverRadius: 5,
						pointBackgroundColor: `rgb(${yellow})`,
					},
				],
			},
			chartOptions: {
				scaleShowVerticalLines: false,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						// enabled: false,
						callbacks: {
							title: function (tooltipItem) {
								return tooltipItem[0].dataset.label;
							},
							label: function (tooltipItem) {
								return tooltipItem.formattedValue;
							},
						},
						backgroundColor: "#FFF",
						titleSize: 16,
						titleColor: "#31129C",
						bodyColor: "#000B33",
						bodySize: 14,
						displayColors: false,
						bodyFont: {
							size: 20,
						},
					},
				},
				scales: {
					// x: {
					// 	grid: {
					// 		display: false,
					// 	},
					// 	ticks: {
					// 		color: "#ABAFB3",
					// 	},
					// },
					y: {
						beginAtZero: true,
						grid: {
							tickLength: 7,
							tickWidth: 0,
							color: "#E3E3E3",
						},
						ticks: {
							labelOffset: 2,
							color: "#ABAFB3",
						},
					},
				},
			},
		}),
		methods: {
			renderChart() {
				let challenges = [],
					hints = [],
					totalPoints = null,
					totalHints = null;

				this.pointsHistoric.forEach((element) => {
					challenges.push({
						x: this.$helper.formatDate(
							element.created_at,
							"YYYY-MM-DD HH:mm:ss"
						),
						y: element.challenge.points.points,
					});

					totalPoints += element.challenge.points.points;

					hints.push({
						x: this.$helper.formatDate(
							element.created_at,
							"YYYY-MM-DD HH:mm:ss"
						),
						y: element.hints,
					});

					totalHints += element.hints;
				});

				this.$emit("update:totalPoints", totalPoints);
				this.$emit("update:totalHints", totalHints);

				this.chartData.datasets[0].data = challenges;
				this.chartData.datasets[1].data = hints;

				let max = new Date();

				let unit = null,
					stepSize = null,
					min = null,
					firstChallengeDate = null,
					lastChallengeDate = null,
					firstAndLastSameDay = false;

				if (this.pointsHistoric.length > 1) {
					firstChallengeDate = new Date(this.pointsHistoric[0].created_at);

					firstChallengeDate = this.$helper.formatDate(
						firstChallengeDate,
						"YYYY-MM-DD"
					);

					lastChallengeDate = new Date(
						this.pointsHistoric[this.pointsHistoric.length - 1].created_at
					);

					lastChallengeDate = this.$helper.formatDate(
						lastChallengeDate,
						"YYYY-MM-DD"
					);

					firstAndLastSameDay = firstChallengeDate === lastChallengeDate;
				}

				switch (this.filter) {
					case "24 H":
						min = new Date();
						min.setHours(min.getHours() - 24);

						unit = "minute";
						stepSize = 30;

						break;

					case "7 D":
						min = this.firstDate(min, 7);
						unit = "day";
						stepSize = 1;

						break;

					case "2 S":
						min = this.firstDate(min, 14);
						unit = "day";
						stepSize = 1;

						break;

					case "3 S":
						min = this.firstDate(min, 21);
						unit = "day";
						stepSize = 2;

						break;

					case "1 M":
						min = this.firstDate(min, 30);
						unit = "day";
						stepSize = 2;

						break;

					case "3 M":
						min = this.firstDate(min, 90);
						unit = "day";
						stepSize = 5;

						break;

					case "1 A":
						min = this.firstDate(min, 365);
						unit = "month";
						stepSize = 1;

						break;

					case "TODO":
						if (
							this.pointsHistoric.length == 0 ||
							this.pointsHistoric.length == 1
						) {
							min = this.firstDate(min, 1);
							unit = "minute";
						} else if (firstAndLastSameDay) {
							min = new Date(this.pointsHistoric[0].created_at);
							unit = "minute";
						} else {
							min = new Date(this.pointsHistoric[0].created_at);
							unit = "day";
						}

						stepSize = 1;

						break;
				}

				this.chartOptions.scales.x = {
					type: "time",
					min,
					max,
					time: {
						unit,
						stepSize,
						displayFormats: {
							minute: "HH:mm",
							day: "DD/MM",
							month: "MMMM",
						},
					},
					grid: {
						display: false,
					},
					ticks: {
						color: "#ABAFB3",
					},
				};

				this.refresh();
			},
			firstDate(min, days) {
				min = new Date();

				min.setDate(min.getDate() - days);
				min.setHours(0, 0, 0, 0);

				return min;
			},
			refresh() {
				this.$refs.Chart.refresh();
			},
		},
		mounted() {
			const chart = document.getElementById("chart");
			const canvas = chart.getElementsByTagName("canvas")[0];

			const ctx = canvas.getContext("2d");

			const blueGradient = ctx.createLinearGradient(0, 0, 0, 400);

			blueGradient.addColorStop(0, `rgba(${blue}, .3)`);
			blueGradient.addColorStop(1, `rgba(${blue}, 0)`);

			this.chartData.datasets[0].backgroundColor = blueGradient;

			const yellowGradient = ctx.createLinearGradient(0, 0, 0, 400);

			yellowGradient.addColorStop(0, `rgba(${yellow}, .3)`);
			yellowGradient.addColorStop(1, `rgba(${yellow}, 0)`);

			this.chartData.datasets[1].backgroundColor = yellowGradient;
		},
		computed: {
			...mapState(["profileStats"]),
		},
	};
</script>

<style lang="scss" scoped>
	#chart {
		width: 100%;
		aspect-ratio: 3/1;

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			aspect-ratio: 1.5/1;
		}
	}
</style>
