<template>
	<div class="container">
		<div
			class="banner w-100 d-flex justify-content-center p-3 bg-light-secondary"
		>
			<div class="d-flex flex-column align-items-center gap-3">
				<div
					class="challenges-icon d-flex justify-content-center align-items-center bg-white p-3"
				>
					<img
						src="@/assets/images/web/ranking-main.png"
						alt="Logo"
						width="50"
					/>
				</div>
				<h1 class="mb-0 text-dark fw-bold text-center">Ranking</h1>
			</div>
		</div>
		<Podium class="mt-md-5" />
		<RankingTable class="mt-3 mt-md-5" :rows="rows" />
	</div>
</template>

<script>
	import Podium from "@/components/web/ranking/Podium.vue";
	import RankingTable from "@/components/web/ranking/RankingTable.vue";
	import { mapActions } from "vuex";

	export default {
		components: {
			Podium,
			RankingTable,
		},
		data() {
			return {
				rows: 7,
			};
		},
		methods: {
			...mapActions(["topThree", "getRanking"]),
		},
		mounted() {
			this.topThree();
			this.getRanking({
				page: 0,
				rows: this.rows,
			});
		},
	};
</script>

<style lang="scss" scoped>
	.banner {
		background-image: url("@/assets/images/web/question-icons.png");
		background-position: center;
		background-size: cover;
		border-radius: 20px;

		.challenges-icon {
			width: 7rem;
			border-radius: 20px;
			aspect-ratio: 1;
		}
	}
</style>
