<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="categories.list"
		:total="categories.listTotal"
		:filters="filters"
		:delete="'categories'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir categoría'"
	>
		<template #columns>
			<Column header="Nombre" field="name" />
			<Column header="Acciones">
				<template #body="slotProps">
					<Button class="table-custom-button-action d-flex gap-3">
						<img
							v-if="$helper.checkUserHasPermission(route, 'update')"
							src="@/assets/images/icons/edit.png"
							@click="showRegister(slotProps.data, 'edit')"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/delete.png"
							@click="deleteRegister(slotProps.data.id)"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "name",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
			]),
			addCategory() {
				this.toggleFormDialog({
					stateVariable: "categories",
					show: true,
				});
			},
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.changeFormDialogMode({
						stateVariable: this.stateVariable,
						dialogMode: type,
					});
				}
			},
			deleteRegister(id) {
				this.$confirm.require({
					message: "¿Desea borrar el registro?",
					header: "Borrar registro",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/${this.route}/${id}`,
						}).then(() => {
							this.$refs.table.getList();
						});
					},
				});
			},
		},
		computed: {
			...mapState(["categories"]),
			noImage() {
				return require("@/assets/images/no-image.png");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.image {
		height: 60px;
		width: 90px;
		background-position: center;
		background-size: cover;
		border-radius: $border-radius;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
