<template>
	<div class="challenge border-radius d-flex flex-column box-shadow">
		<div
			v-if="showPending"
			class="show-pending d-flex justify-content-center align-items-center text-white fw-bold text-center"
		>
			<div class="col-10 col-8">Pendiente de aprobación</div>
		</div>
		<div
			class="image"
			:style="{
				backgroundImage: `url(${image != null ? image : defaultBackground})`,
			}"
		>
			<div
				v-if="showStatus && !challengeFinished"
				class="status py-1 text-white text-center small"
				:class="[status]"
			>
				{{ statusText }}
			</div>
		</div>
		<div class="d-flex flex-column gap-2 p-3">
			<div class="h5 fw-bold mb-0">
				{{ name }}
			</div>
			<div class="d-flex justify-content-between align-items-center">
				<span>{{ category }}</span>
				<div
					class="difficulty px-2 py-1 border-radius small"
					:class="[difficulty]"
				>
					{{ difficultyText }}
				</div>
			</div>
			<div class="text-muted small">{{ date }}</div>
			<div v-if="challengeFinished">
				<Button label="Reto finalizado" class="w-100" disabled />
			</div>
			<div v-else>
				<Button
					v-if="showAddHintsButton"
					label="Añadir pistas"
					class="w-100 bg-warning d-flex justify-content-center gap-2 align-items-center"
					@click="$emit('add-hints', id)"
				>
					<img src="@/assets/images/web/add-hints.png" width="20" />
					Añadir pistas
				</Button>
				<Button
					v-else-if="status == 'without-start'"
					label="Comenzar reto"
					class="w-100"
					@click="startAction()"
				/>
				<Button
					v-else-if="status == 'started'"
					label="Continuar reto"
					class="w-100"
					@click="$router.push(`/challenge/${id}`)"
				/>
				<Button
					v-else-if="status == 'quitted'"
					label="Reto abandonado"
					class="w-100 bg-danger"
					v-tooltip.bottom="'¿Reiniciar reto?'"
					@click="startAction()"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapState } from "vuex";

	export default {
		props: {
			id: {
				type: Number,
				required: true,
			},
			image: {
				type: String,
				required: true,
			},
			showStatus: {
				type: Boolean,
				default: true,
			},
			status: {
				type: String,
				default: null,
			},
			name: {
				type: String,
				required: true,
			},
			category: {
				type: String,
				required: true,
			},
			difficulty: {
				type: String,
				required: true,
			},
			date: {
				type: String,
				required: true,
			},
			showAddHintsButton: {
				type: Boolean,
				default: false,
			},
			showPending: {
				type: Boolean,
				default: false,
			},
			endDate: {
				type: String,
				default: null,
			},
		},
		methods: {
			...mapActions(["startChallenge"]),
			startAction() {
				if (this.userLogged != null) {
					this.startChallenge({
						id: this.id,
					}).then(() => {
						this.$emit("status-changed");
					});
				} else {
					this.$router.push(`/challenge/${this.id}`);
				}
			},
		},
		computed: {
			...mapState(["userLogged"]),
			statusText() {
				switch (this.status) {
					case "without-start":
						return "Sin iniciar";
					case "started":
						return "Iniciado";
					case "solved":
						return "Completado";
					case "quitted":
						return "Abandonado";
					default:
						return "Sin iniciar";
				}
			},
			difficultyText() {
				switch (this.difficulty) {
					case "easy":
						return "Fácil";
					case "medium":
						return "Medio";
					case "hard":
						return "Difícil";
					default:
						return "Fácil";
				}
			},
			challengeFinished() {
				// if end date is not null, and is before today, return true
				if (this.endDate) {
					const today = new Date();
					const endDate = new Date(this.endDate);
					return endDate < today;
				}

				return false;
			},
			defaultBackground() {
				return require(`@/assets/images/black-logo.png`);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.challenge {
		border-bottom: 3px solid $primary;
		position: relative;
		overflow: hidden;

		.show-pending {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba($primary, 0.5);
			font-size: 1.5rem;
			z-index: 5;
		}

		.image {
			width: 100%;
			aspect-ratio: 1;
			position: relative;
			background-position: center;
			background-size: cover;

			.status {
				position: absolute;
				top: 1rem;
				left: 0;
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
				width: 8rem;

				&.without-start {
					background-color: $info;
				}

				&.started {
					background-color: $warning;
				}

				&.solved {
					background-color: $success;
				}

				&.quitted {
					background-color: $danger;
				}
			}
		}

		.difficulty {
			&.easy {
				background-color: $light-success;
				color: $success;
			}

			&.medium {
				background-color: $light-warning;
				color: $warning;
			}

			&.hard {
				background-color: $light-danger;
				color: $danger;
			}
		}
	}
</style>
