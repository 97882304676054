<template>
	<div>
		<Button
			class="d-flex align-items-center gap-2"
			@click="toggleShowHints(false)"
		>
			<i class="pi pi-chevron-left"></i>
			<span>Volver</span>
		</Button>
		<SelectedChallenge class="mt-4" />
		<HintsTable class="mt-4" :route="route" :stateVariable="stateVariable" />
		<HintsForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import SelectedChallenge from "./SelectedChallenge.vue";
	import HintsTable from "./HintsTable.vue";
	import HintsForm from "./HintsForm.vue";

	import { mapMutations } from "vuex";

	export default {
		components: {
			SelectedChallenge,
			HintsTable,
			HintsForm,
		},
		data() {
			return {
				route: "hints",
				stateVariable: "hints",
			};
		},
		methods: {
			...mapMutations(["toggleShowHints"]),
		},
	};
</script>
