<template>
	<TableDefault
		v-if="additionalParams.challenge_id != null"
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="hints.list"
		:total="hints.listTotal"
		:filters="filters"
		:additionalParams="additionalParams"
		:delete="'hints'"
		:customTitle="'Pistas'"
		:rows="99"
		:rowsPerPageOptions="[99]"
		:rowReorder="true"
		:showSelectMultipleColumn="false"
		@onRowReorder="onRowReorder($event)"
		:addButtonText="'Añadir pista'"
	>
		<template #columns>
			<!-- <Column header="Orden" field="order"></Column> -->
			<Column header="Enunciado">
				<template #body="slotProps">
					<LongTextColumn :data="slotProps.data.statement" />
				</template>
			</Column>
			<Column header="Solución">
				<template #body="slotProps">
					<LongTextColumn :data="slotProps.data.solution" />
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";
	require("@/assets/images/white-logo.png");

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "statement",
						value: null,
						type: "string",
						placeholder: "Enunciado",
					},
					{
						name: "solution",
						value: null,
						type: "string",
						placeholder: "Solución",
					},
				],
				additionalParams: {
					challenge_id: null,
				},
			};
		},
		methods: {
			...mapActions([
				"deleteRegisters",
				"getList",
				"publishChallenge",
				"reorderHints",
			]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
			]),
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.changeFormDialogMode({
						stateVariable: this.stateVariable,
						dialogMode: type,
					});
				}
			},
			deleteRegister(id, active) {
				const lastPageChange = this.$refs.table.lastPageChange;

				this.$confirm.require({
					message: active
						? "¿Desea bloquear el usuario?"
						: "¿Desea desbloquear el usuario?",
					header: "Cambiar estado",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/hints/${id}`,
						}).then(() => {
							this.$refs.table.getList(lastPageChange);
						});
					},
				});
			},
			publish(id) {
				this.publishChallenge({ id }).then(() => {
					this.$refs.table.getList();
				});
			},
			showHints(hint) {
				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					hint,
				});
			},
			onRowReorder(event) {
				this.reorderHints(event.value).then(() => {
					this.$refs.table.getList();
				});
			},
		},
		computed: {
			...mapState(["hints", "challenges"]),
		},
		mounted() {
			this.additionalParams.challenge_id = this.challenges.register.id;
		},
	};
</script>

<style lang="scss" scoped>
	.easy {
		background: $light-success;
		color: $success;
		width: 5rem;
	}

	.medium {
		background: $light-warning;
		color: $warning;
		width: 5rem;
	}

	.hard {
		background: $light-danger;
		color: $danger;
		width: 5rem;
	}

	.image {
		height: 60px;
		width: 90px;
		background-position: center;
		background-size: cover;
		border-radius: $border-radius;
	}
</style>
