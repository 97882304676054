<template>
	<div>
		<Header />
		<Challenges class="mt-3 mt-md-5" />
	</div>
</template>

<script>
	import Header from "@/components/web/home/Header.vue";
	import Challenges from "@/components/web/home/Challenges.vue";

	export default {
		components: {
			Header,
			Challenges,
		},
	};
</script>
