<template>
	<div>
		<div v-if="name == 'easy'" class="easy px-3 py-2 border-radius text-center">
			Fácil
		</div>
		<div
			v-else-if="name == 'medium'"
			class="medium px-3 py-2 border-radius text-center"
		>
			Medio
		</div>
		<div
			v-else-if="name == 'hard'"
			class="hard px-3 py-2 border-radius text-center"
		>
			Difícil
		</div>
		<div v-else class="info px-3 py-2 border-radius text-center">
			{{ name }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.easy {
		background: $light-success;
		color: $success;
	}

	.medium {
		background: $light-warning;
		color: $warning;
	}

	.hard {
		background: $light-danger;
		color: $danger;
	}

	.info {
		background: $light-info;
		color: $info;
	}
</style>
