<template>
	<Dialog
		v-model:visible="$store.state.use_hints.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="false"
		:closable="false"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<div v-if="slide == 0" class="row gy-3 pt-3">
			<div class="col-12">
				<FormSubtitle
					title="Antes de solicitar una pista, sigue atentamente las instrucciones."
				/>
			</div>
			<div class="col-12">
				Te iremos proporcionando las pistas siguiendo el orden lógico de la
				investigación, tienes 20 segundos para contestar a la pregunta de la
				pista. Si lo haces correctamente, es que ya conocías ese dato y
				automáticamente tendrás la opción de ver la siguiente pista sin que se
				te descuente de tu cuenta.
			</div>
		</div>
		<div v-else class="row gy-3 pt-3">
			<div v-if="deadline" class="col-12 d-flex justify-content-center">
				<!-- <div id="registerBy" class="flipdown"></div> -->
				<Countdown
					:deadline="deadline"
					:showDays="false"
					:showHours="false"
					:showMinutes="false"
					:mainColor="'white'"
					:mainFlipBackgroundColor="'#31129c'"
					:secondFlipBackgroundColor="'#230d6e'"
					:showLabels="false"
					:countdownSize="'3rem'"
					:stop="stop"
					@timeElapsed="timeEnded()"
				/>
			</div>
			<div class="col-12 mt-3">
				<div class="text-center text-dark fw-bold h5 mb-0">
					{{ challenge.hints[slide - 1].statement }}
				</div>
				<div class="d-flex justify-content-center mt-2">
					<Play :text="challenge.hints[slide - 1].statement" />
				</div>
			</div>
			<div class="col-12">
				<form v-on:submit.prevent="checkAnswer()">
					<InputText
						ref="userAnswer"
						label="Solución"
						:disabled="disabled"
						:error="form.errors.get('userAnswer')"
						@change-value="(value) => (form.userAnswer = value)"
					/>
				</form>
			</div>
			<div v-if="!disabled" class="col-12 d-flex justify-content-between">
				<Button
					@click="close()"
					label="Volver "
					:disabled="form.busy"
					class="bg-white border border-primary text-primary"
				/>
				<Button
					@click="checkAnswer()"
					label="Responder"
					:disabled="form.busy"
				/>
			</div>
		</div>

		<template v-if="showFooter" #footer>
			<div class="col-12 d-flex justify-content-between">
				<Button
					v-if="
						slide == 0 || (challenge.hints.length != slide && answerCorrect)
					"
					@click="close()"
					label="Volver "
					:disabled="form.busy"
					class="bg-white border border-primary text-primary"
				/>
				<Button
					v-if="slide == 0"
					@click="start()"
					label="Comenzar"
					:disabled="form.busy"
				/>
				<Button
					v-else-if="challenge.hints.length != slide && answerCorrect"
					@click="next()"
					label="Siguiente pista"
					:disabled="form.busy"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	import Play from "@/components/web/partials/Play.vue";

	import { Countdown } from "vue3-flip-countdown";

	export default {
		components: {
			Countdown,
			Play,
		},
		props: {
			challenge: {
				type: Object,
				default: () => ({}),
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "reto",
			slide: 0,
			answerCorrect: false,
			showTimer: false,
			disabled: false,
			deadline: null,
			stop: false,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters", "discountHint"]),
			...mapMutations([
				"toggleFormDialog",
				"changeCurrentRegister",
				"changeSuccessToast",
				"changeErrorToast",
				"changeWarningToast",
				"toggleAllHintsSolved",
			]),
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (
						Object.hasOwnProperty.call(this.$refs, key) &&
						this.$refs[key] != undefined
					) {
						this.$refs[key].model = null;
					}
				}
			},
			close() {
				this.slide = 0;
				this.deadline = null;
				this.answerCorrect = false;
				this.clearForm();

				this.toggleFormDialog({
					stateVariable: "use_hints",
					show: false,
				});
			},
			show() {
				this.disabled = false;
				this.clearForm();

				if (
					this.challenge.type == "competitive" ||
					(this.challenge.type == "training" && this.challenge.level != "easy")
				) {
					if (this.userLogged) {
						this.discountHint({
							challenge_id: this.challenge.id,
						});
					} else {
						this.toggleAllHintsSolved(true);
					}
				}
			},
			start() {
				this.slide = 1;
				this.setDeadline();
			},
			next() {
				this.disabled = false;
				this.slide++;
				this.deadline = null;

				this.$nextTick(() => {
					this.setDeadline();
				});
			},
			checkAnswer() {
				const userAnswer = this.form.userAnswer?.trim();

				let solved = false;

				this.solutions.forEach((solution) => {
					if (solution === userAnswer.trim()) {
						solved = true;
					}
				});

				if (solved) {
					this.answerCorrect = true;
					this.disabled = true;

					this.stop = true;
					this.changeSuccessToast("Respuesta correcta");
					this.timeEnded();
					this.clearForm();
				} else {
					this.changeErrorToast("Respuesta incorrecta");
				}
			},
			setDeadline() {
				const seconds = 31;
				const date = new Date(Date.now() + seconds * 1000);

				this.stop = false;
				this.deadline = this.$helper.formatDate(date, "yyyy-MM-DD HH:mm:ss");
				this.answerCorrect = false;
			},
			timeEnded() {
				if (this.challenge.hints.length == this.slide && this.answerCorrect) {
					this.toggleAllHintsSolved(true);

					this.toggleFormDialog({
						stateVariable: "use_hints",
						show: false,
					});
				} else if (!this.answerCorrect) {
					this.changeWarningToast("Tiempo agotado");

					this.close();
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "challenges", "categories", "userLogged"]),
			title() {
				if (this.slide == 0) {
					return `Instrucciones sobre las pistas`;
				} else {
					return `Pista ${this.slide}/${this.challenge.hints.length}`;
				}
			},
			solutions() {
				const challengeSolutions =
					this.challenge.hints[this.slide - 1].solution.split("@");

				challengeSolutions.forEach((challengeSolution, index) => {
					challengeSolutions[index] = challengeSolution.trim();
				});

				return challengeSolutions;
			},
			showFooter() {
				return (
					this.slide == 0 ||
					(this.challenge.hints.length != this.slide && this.answerCorrect)
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	:deep(.flip-card) {
		transform: scale(0.9, 1.1);
	}
</style>
