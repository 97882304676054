<template>
	<Card class="custom-card">
		<template #content>
			<div class="d-flex justify-content-between align-items-center">
				<Breadcrumb />
				<CurrentUser />
			</div>
		</template>
	</Card>
</template>

<script>
	import Card from "primevue/card";
	import Breadcrumb from "../partials/Breadcrum.vue";
	import CurrentUser from "../partials/CurrentUser.vue";

	export default {
		components: {
			Breadcrumb,
			Card,
			CurrentUser,
		},
	};
</script>

<style lang="scss" scoped>
	.custom-card {
		padding: 0;
		border-radius: 0;

		:deep(.p-card-content) {
			padding: 0;
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			:deep(.p-card-body) {
				padding: 0;
			}
		}
	}
</style>
