<script>
	import { loadStripe } from "@stripe/stripe-js";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		data() {
			return {
				publicKey: process.env.VUE_APP_STRIPE_PUBLIC,
				stripe: null,
				stripePromise: null,
				priceId: "price_1NUpOfIssQICwnF41AXRkYYY",
				quantity: 0,
			};
		},
		methods: {
			...mapActions(["orderFinished", "orderCanceled", "hintsPrice"]),
			...mapMutations([
				"changeSuccessToast",
				"changeWarningToast",
				"changeErrorToast",
				"changeUserLoggedHints",
			]),
			async handlePayment() {
				if (this.quantity * this.hints_price < 0.5) {
					this.changeWarningToast("El importe debe ser der al menos 0.50€");
					return;
				}

				const origin = window.location.origin;

				this.stripe = await this.stripePromise;

				const { error } = await this.stripe.redirectToCheckout({
					lineItems: [{ price: this.priceId, quantity: this.quantity }],
					mode: "payment",
					successUrl: `${origin}?payment=pay-success&orderHash=${this.buy_hints.orderHash}`,
					cancelUrl: `${origin}?payment=pay-cancel&orderHash=${this.buy_hints.orderHash}`,
				});

				if (error) {
					this.changeErrorToast("Se ha producido un error al realizar el pago");
					console.error(error);
				}
			},
			checkStatus() {
				if (this.$route.query?.payment == "pay-success") {
					this.changeSuccessToast(
						"Muchas gracias por tu aportación. Nos ayudará a seguir trabajando con toda la ilusión y a mantener la página abierta para todos vosotros."
					);
					this.orderFinished({
						orderHash: this.$route.query.orderHash,
					}).then((response) => {
						this.changeUserLoggedHints(response.data.user.hints);
					});
				}

				if (this.$route.query?.payment == "pay-cancel") {
					this.changeWarningToast("Compra cancelada");
					this.orderCanceled({
						orderHash: this.$route.query.orderHash,
					});
				}

				this.$router.replace({ query: null });
			},
		},
		computed: {
			...mapState(["buy_hints", "hints_price"]),
		},
		mounted() {
			this.stripePromise = loadStripe(this.publicKey, {
				locale: "es",
			});

			this.checkStatus();
			this.hintsPrice();
		},
		watch: {
			"buy_hints.quantity": {
				handler() {
					this.quantity = this.buy_hints.quantity;

					if (this.quantity <= 0) {
						this.changeWarningToast("Debes comprar al menos una pista");
						return;
					}

					this.handlePayment();
				},
				immediate: true,
			},
		},
	};
</script>
