<template>
	<Dialog
		v-model:visible="$store.state.challenges_quit.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@show="show"
	>
		<template #header>
			<FormTitle :title="challenge.name" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12 d-flex justify-content-center">
					<img src="@/assets/images/web/challenges-error.png" width="150" />
				</div>
				<div
					class="col-12 d-flex flex-column align-items-center justify-content-center"
				>
					<p class="text-dark text-center fw-bold h5 mb-3">Abandonar reto</p>
					<p class="text-dark text-center fw-bold mb-3">
						¿Está segur@ de que desea abandonar el reto?
					</p>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: 'challenges_quit',
							show: false,
						})
					"
					label="Cancelar"
				/>
				<Button @click="quit()" label="Abandonar reto" class="bg-danger" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			challenge: {
				type: Object,
				default: () => ({}),
			},
		},
		computed: {
			...mapState(["dialogDefaults", "userLogged"]),
		},
		methods: {
			...mapActions(["quitChallenge"]),
			...mapMutations(["toggleFormDialog"]),
			quit() {
				if (this.userLogged) {
					this.quitChallenge({
						id: this.$route.params.id,
					}).then(() => {
						this.$emit("status-changed");

						this.toggleFormDialog({
							stateVariable: "challenges_quit",
							show: false,
						});
					});
				} else {
					this.toggleFormDialog({
						stateVariable: "challenges_quit",
						show: false,
					});

					this.$router.push({
						name: "Inicio",
					});
				}
			},
		},
	};
</script>
