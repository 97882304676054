<template>
	<div
		class="add-challenge rounded h-100 w-100 d-flex justify-content-center align-items-center flex-column gap-2 cursor-pointer py-5"
		@clikc="$emmit('click')"
	>
		<div class="circle">
			<i class="pi pi-plus text-grey"></i>
		</div>
		<div class="text-grey">Crear nuevo reto</div>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.add-challenge {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='" + $border-radius + "' ry='" + $border-radius + "' stroke='%23ABAFB3FF' stroke-width='4' stroke-dasharray='20%2c 20' stroke-dashoffset='5' stroke-linecap='round'/%3e%3c/svg%3e");
		transition: $transition;

		.circle {
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23ABAFB3FF' stroke-width='4' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
			border-radius: 100px;
			width: 64px;
			height: 64px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.08);
		}
	}
</style>
