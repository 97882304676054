<template>
	<div class="container">
		<div
			class="banner w-100 d-flex justify-content-center p-3 bg-light-secondary"
		>
			<div class="d-flex flex-column align-items-center gap-3">
				<div
					class="challenges-icon d-flex justify-content-center align-items-center bg-white p-3"
				>
					<img
						src="@/assets/images/web/challenges-icon.png"
						alt="Logo"
						width="50"
					/>
				</div>
				<h1 class="mb-0 text-dark fw-bold text-center">Retos</h1>
			</div>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.banner {
		background-image: url("@/assets/images/web/question-icons.png");
		background-position: center;
		background-size: cover;
		border-radius: 20px;

		.challenges-icon {
			width: 7rem;
			border-radius: 20px;
			aspect-ratio: 1;
		}
	}
</style>
