<template>
	<Dialog
		v-model:visible="$store.state.buy_hints.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12 d-flex justify-content-center">
					<img src="@/assets/images/web/buy-hints-header.png" width="150" />
				</div>
				<div
					class="col-12 d-flex flex-column align-items-center justify-content-center"
				>
					<p class="text-dark fw-bold h5 mb-3">
						¿Cuántas pistas quieres comprar?
					</p>
					<div class="d-flex align-items-center justify-content-center gap-2">
						<Button
							class="action-button"
							icon="pi pi-minus"
							@click="substract"
						/>
						<InputText
							v-model="form.quantity"
							class="w-50 custom-input text-center"
							placeholder="Cantidad"
						/>
						<Button class="action-button" icon="pi pi-plus" @click="add" />
					</div>
					<div v-if="form.errors.get('quantity') != null" class="text-danger">
						{{ form.errors.get("quantity") }}
					</div>
				</div>
				<div
					class="col-12 d-flex flex-column align-items-center justify-content-center"
				>
					<p class="text-dark fw-bold mb-3">TOTAL</p>
					<FormSubtitle :title="totalPriceText" />
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: 'buy_hints',
							show: false,
						})
					"
					label="Cerrar "
					:disabled="form.busy"
					class="bg-white border border-primary text-primary"
				/>
				<Button @click="buy()" label="Comprar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";
	import InputText from "primevue/inputtext";

	export default {
		components: {
			InputText,
		},
		data: () => ({
			form: new Form({
				quantity: 0,
			}),
			modelName: "reto",
			title: `Comprar pistas`,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations([
				"toggleFormDialog",
				"changeCurrentRegister",
				"changeUserLoggedHints",
				"changeBuyHintsQuantity",
				"changeWarningToast",
				"changeBuyHintsOrderHash",
			]),
			buy() {
				if (this.form.quantity <= 0) {
					this.changeWarningToast("Debes comprar al menos una pista");
					return;
				}

				const url = `/buy-hints`;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
					messages: {
						show: false,
					},
				}).then((response) => {
					if (response.status === 200) {
						this.changeBuyHintsQuantity(this.form.quantity);
						this.changeBuyHintsOrderHash(response.data.order.hash);
					}
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();
			},
			show() {
				this.clearForm();
			},
			substract() {
				if (this.form.quantity > 0) {
					this.form.quantity--;
				}
			},
			add() {
				this.form.quantity++;
			},
		},
		computed: {
			...mapState(["dialogDefaults", "challenges", "categories", "hints_price"]),
			totalPriceText() {
				let total = this.form.quantity * this.hints_price;
				total = total.toFixed(2);

				return `${total} EUR`;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.custom-input {
		height: 4rem;
	}
	.action-button {
		height: 3rem !important;
		width: 3rem !important;
	}
</style>
