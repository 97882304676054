<template>
	<div v-if="adImage != null">
		<div class="container">
			<div class="image-container rounded overflow-hidden box-shadow mx-auto">
				<img class="image" :src="adImage" />
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapState } from "vuex";

	export default {
		data: () => ({
			adImage: null,
		}),
		methods: {
			...mapActions(["getAdd"]),
			get() {
				this.getAdd().then((response) => {
					this.adImage = response.data.adImage;
				});
			},
		},
		computed: {
			...mapState(["ads"]),
		},
		mounted() {
			this.get();
		},
	};
</script>

<style lang="scss" scoped>
	.image-container {
		max-width: 50rem;

		img {
			width: 100%;
			min-height: 100%;
			object-fit: cover;
		}
	}
</style>
