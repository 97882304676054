<template>
	<Dialog
		v-model:visible="$store.state.users.infoDialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '40vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle title="Info. Usuario" />
		</template>

		<div class="row pt-3">
			<div class="col-12 d-flex justify-content-between">
				<div v-if="user" class="d-flex gap-3">
					<Avatar
						size="xlarge"
						shape="circle"
						class="bg-light-primary text-white"
						:label="$helper.firstTwoLetters(user?.name)"
						style="width: 6rem; height: 6rem"
					/>
					<div class="d-flex flex-column">
						<p class="mb-0">
							<b>{{ user?.name }}</b>
						</p>
						<p class="text-muted mb-2">{{ user?.email }}</p>
						<div
							v-if="user?.role_id == 1"
							class="bg-secondary px-2 py-1 text-white border-radius text-center"
						>
							{{ user?.role.slug }}
						</div>
						<div
							v-else
							class="bg-light-primary text-white px-2 py-1 border-radius text-center"
						>
							{{ user?.role.slug }}
						</div>
					</div>
				</div>
				<Knob
					v-model="level"
					:strokeWidth="5"
					valueTemplate="Nivel {value}"
					:size="120"
					valueColor="#0061ff"
					rangeColor="#e0efff"
					style="margin-top: -0.5rem"
					readonly
				/>
			</div>

			<div class="col-12">
				<hr />
			</div>

			<div
				class="d-flex align-items-center flex-column text-center col-6 col-md-3"
			>
				<b>Fecha alta</b>
				{{ $helper.formatDate(user?.created_at, "DD/MM/YYYY") }}
			</div>
			<div
				class="d-flex align-items-center flex-column text-center col-6 col-md-3"
			>
				<b>Puntos Exp.</b>
				{{ user?.user_level.points }}
			</div>
			<div
				class="d-flex align-items-center flex-column text-center col-6 col-md-3"
			>
				<b>Retos completos</b>
				{{ solved }}
			</div>
			<div
				class="d-flex align-items-center flex-column text-center col-6 col-md-3"
			>
				<b>Retos comenzados</b>
				{{ started }}
			</div>

			<div class="col-12 col-md-6 mt-3">
				<FormSubtitle :title="'Compras realizadas: ' + user?.orders_count" />
			</div>

			<div class="col-12 col-md-6 mt-3">
				<FormSubtitle
					:title="'Pistas restantes: ' + user?.hints"
					theme="yellow"
				/>
			</div>
		</div>

		<template #footer>
			<div class="col-12 d-flex justify-content-center">
				<Button
					@click="toggleInfoDialog(false)"
					label="Cerrar"
					class="bg-primary text-white border border-primary"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Knob from "primevue/knob";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			Avatar,
			Knob,
		},
		data: () => ({
			user: null,
			level: 50,
			solved: 0,
			started: 0,
		}),
		methods: {
			...mapMutations(["toggleInfoDialog"]),
			show() {
				this.user = this.users.register;

				this.user.user_has_challenges.forEach((user_has_challenge) => {
					if (user_has_challenge.status == "solved") {
						this.solved++;
					} else if (user_has_challenge.status == "started") {
						this.started++;
					}
				});

				this.level = this.user.user_level.level;
			},
		},
		computed: {
			...mapState(["dialogDefaults", "users"]),
		},
	};
</script>

<style lang="scss" scoped>
	:deep(.p-knob-text) {
		font-size: 1rem;
	}
</style>
