import axios from "axios";
import state from "./store/state";

const http = axios.create({
  baseURL: state.baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export default http;
