<template>
	<Dialog
		v-model:visible="$store.state.updatePasswordDialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="'Cambiar contraseña'" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div
					v-if="
						!userLogged.social_login ||
						(userLogged.social_login && userLogged.has_updated_password)
					"
					class="col-12"
				>
					<InputPassword
						ref="current_password"
						label="Contraseña actual"
						:disabled="disabled"
						:error="form.errors.get('current_password')"
						@change-value="(value) => (form.current_password = value)"
						:feedback="false"
					/>
				</div>
				<div class="col-12">
					<InputPassword
						ref="password"
						label="Nueva contraseña"
						:disabled="disabled"
						:error="form.errors.get('password')"
						@change-value="(value) => (form.password = value)"
					/>
				</div>
				<div class="col-12">
					<InputPassword
						ref="password_confirm"
						label="Repetir contraseña"
						:disabled="disabled"
						:error="form.errors.get('password_confirm')"
						@change-value="(value) => (form.password_confirm = value)"
						:feedback="false"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="toggleUpdatePasswordDialog(false)"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border border-primary text-primary"
				/>
				<Button @click="save()" label="Guardar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		data: () => ({
			form: new Form(),
			disabled: false,
			lastPageChange: null,
		}),
		methods: {
			...mapActions(["sendForm"]),
			...mapMutations([
				"toggleUpdatePasswordDialog",
				"setUserLoggedUpdatedPassword",
			]),
			save() {
				const url = `/update-user-password`;

				this.sendForm({
					method: "put",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleUpdatePasswordDialog(false);
						this.setUserLoggedUpdatedPassword();
					}
				});
			},
			hide() {
				this.disabled = false;
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();
			},
		},
		computed: {
			...mapState(["dialogDefaults", "updatePasswordDialog", "userLogged"]),
		},
	};
</script>
