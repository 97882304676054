<template>
	<Dialog
		v-model:visible="$store.state.info.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
	>
		<template #header>
			<FormTitle :title="'Información'" />
		</template>

		<div class="row gy-3 pt-3">
			<div class="col-12 d-flex justify-content-center">
				<img src="@/assets/images/black-logo.png" width="150" />
			</div>
			<div
				class="col-12 d-flex flex-column align-items-center justify-content-center"
			>
				<p class="text-dark fw-bold text-center mb-0">
					En el perfil de Usuario podrás editar todos tus datos personales y
					tendrás acceso a las estadísticas de tu historial
				</p>
			</div>
		</div>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-center">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: 'info',
							show: false,
						})
					"
					label="Cerrar"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import { mapMutations, mapState } from "vuex";

	export default {
		computed: {
			...mapState(["dialogDefaults"]),
		},
		methods: {
			...mapMutations(["toggleFormDialog"]),
		},
	};
</script>
