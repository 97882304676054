<template>
	<div class="container">
		<div class="d-flex flex-wrap">
			<div class="col-6 d-flex align-items-end cursor-pointer">
				<Tab
					:active="trainingActive"
					:text="'Entrenamiento'"
					:image="'horse'"
					@click="active('trainingActive')"
				/>
			</div>
			<div class="col-6 d-flex align-items-end cursor-pointer">
				<Tab
					:active="competitiveActive"
					:text="'Competitivo'"
					:image="'trophy'"
					@click="active('competitiveActive')"
				/>
			</div>
		</div>
		<div v-if="challengesList.length > 0" class="row">
			<div
				v-for="challenge in challengesList"
				:key="challenge.id"
				class="col-12 col-md-6 col-lg-3 mt-4"
			>
				<Challenge
					:id="challenge.id"
					:image="challenge.image"
					:status="challengeStatus(challenge)"
					:name="challenge.name"
					:category="challenge.category.name"
					:difficulty="challenge.level"
					:date="
						$helper.formatDate(
							challenge.date != null ? challenge.date : challenge.updated_at,
							'DD/MM/YYYY'
						)
					"
					:endDate="challenge.date + ' ' + challenge.time"
					@status-changed="challenges"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import Tab from "@/components/web/partials/Tab.vue";
	import Challenge from "@/components/web/partials/Challenge.vue";

	import { mapActions, mapState, mapMutations } from "vuex";

	export default {
		components: {
			Tab,
			Challenge,
		},
		data() {
			return {
				trainingActive: true,
				competitiveActive: false,
			};
		},
		methods: {
			...mapActions(["getChallenges"]),
			...mapMutations(["clearChallengesList"]),
			active(tab) {
				this.trainingActive = false;
				this.competitiveActive = false;

				this[tab] = true;

				this.challenges();
			},
			challengeStatus(challenge) {
				if (this.userLogged) {
					return challenge.user_has_challenges.length > 0
						? challenge.user_has_challenges[0].status
						: "without-start";
				} else {
					return "without-start";
				}
			},
			challenges() {
				this.getChallenges({
					type: this.trainingActive ? "training" : "competitive",
					user_id: this.userLogged ? this.userLogged.id : null,
				});
			},
		},
		mounted() {
			this.challenges();
		},
		computed: {
			...mapState(["challengesList", "userLogged"]),
		},
		beforeUnmount() {
			this.clearChallengesList();
		},
	};
</script>
