<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-white title mb-3 mb-md-1">
				<b>Iniciar sesión</b>
			</div>
			<div class="col-12 text-center d-flex gap-2">
				<p class="text-dark">¿No tienes una cuenta?</p>
				<p
					@click="$parent.showForm = 'register'"
					class="cursor-pointer text-primary"
				>
					<b>Crear cuenta</b>
				</p>
			</div>
			<div class="col-12">
				<InputText
					label="Email"
					:error="form.errors.get('email')"
					@change-value="(value) => (form.email = value)"
					:type="'email'"
					:errorClass="'text-white'"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Contraseña"
					:error="form.errors.get('password')"
					:feedback="false"
					@change-value="(value) => (form.password = value)"
					:errorClass="'text-white'"
				/>
				<p
					@click="$parent.showForm = 'forgot'"
					class="text-primary cursor-pointer mt-2 mb-1"
				>
					¿Contraseña perdida?
				</p>
			</div>
			<div class="col-12 d-flex flex-column gap-3 mt-3 mt-md-4">
				<Button
					:disabled="form.busy"
					@click="save()"
					label="Iniciar sesión"
					class="bg-salmon w-100"
				/>
				<div class="d-flex justify-content-between align-items-center gap-3">
					<hr class="w-100" />
					<span class="text-white">o</span>
					<hr class="w-100" />
				</div>
				<div class="d-flex justify-content-center">
					<GoogleLogin />
				</div>
				<!-- <Button
					class="d-flex justify-content-center gap-2 bg-white text-primary w-100"
				>
					<img src="@/assets/images/google.png" width="20" />
					<div class="text-muted"><b>Iniciar sesión con Google</b></div>
				</Button> -->
			</div>
		</div>
	</form>
</template>

<script>
	import GoogleLogin from "@/components/auth/GoogleLogin.vue";
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		components: {
			GoogleLogin,
		},
		data: () => ({
			form: new Form(),
			showForgotPassword: false,
			showRegister: false,
		}),
		methods: {
			...mapActions(["login"]),
			save() {
				this.login({
					form: this.form,
					errors: this.form.errors,
				});
			},
		},
	};
</script>
