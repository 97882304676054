<template>
	<div v-if="challengeDetails != null">
		<Details :challenge="challengeDetails" />
		<Success :challenge="challengeDetails" />
		<Error :challenge="challengeDetails" />
		<UseHints :challenge="challengeDetails" />
		<Quit :challenge="challengeDetails" @status-changed="onStatusChanged" />
		<Image :challenge="challengeDetails" />
	</div>
</template>

<script>
	import Details from "@/components/web/challenge/Details.vue";
	import Success from "@/components/web/challenge/Success.vue";
	import Error from "@/components/web/challenge/Error.vue";
	import UseHints from "@/components/web/challenge/UseHints.vue";
	import Quit from "@/components/web/challenge/Quit.vue";
	import Image from "@/components/web/challenge/Image.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Details,
			Success,
			Error,
			UseHints,
			Quit,
			Image,
		},
		methods: {
			...mapActions(["getChallengeDetails"]),
			...mapMutations(["changeWarningToast", "toggleAllHintsSolved"]),
			onStatusChanged() {
				this.getChallengeDetailsAction();
			},
			getChallengeDetailsAction() {
				this.getChallengeDetails({
					id: this.$route.params.id,
					user_id: this.userLogged?.id,
				}).then(() => {
					if (
						this.userLogged &&
						this.challengeDetails.user_has_challenges.length == 0
					) {
						this.goHome();
					} else if (
						this.userLogged &&
						this.challengeDetails.user_has_challenges[0].user_id !=
							this.userLogged.id
					) {
						this.goHome();
					} else if (
						this.challengeDetails.statement == null ||
						this.challengeDetails.solution == null
					) {
						this.goHome();
					}
				});
			},
			goHome() {
				this.$router.push({
					name: "Inicio",
				});
			},
			listenChallengeEndDate() {
				const interval = setInterval(() => {
					const now = new Date();
					const endDate = new Date(
						this.challengeDetails.date + " " + this.challengeDetails.time
					);

					if (now > endDate) {
						this.changeWarningToast("El reto ha terminado");
						this.goHome();

						clearInterval(interval);
					}
				}, 1000);
			},
		},
		computed: {
			...mapState(["challengeDetails", "userLogged"]),
		},
		mounted() {
			this.getChallengeDetailsAction();
			this.listenChallengeEndDate();
		},
		beforeUnmount() {
			this.toggleAllHintsSolved(false);
		},
	};
</script>
