<template>
	<router-link
		:to="item.href"
		class="link d-flex align-items-center p-3 gap-2 p-ripple"
		v-ripple
	>
		<img :src="image" width="20" />
		<p class="custom-line-heigth mb-0">
			{{ item.label }}
		</p>
	</router-link>
</template>

<script>
	export default {
		props: ["item"],
		computed: {
			image() {
				const img = this.item.active
					? this.item.permission + "-selected"
					: this.item.permission;
				return require(`@/assets/images/sidebar/${img}.png`);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.link {
		text-decoration: none;
		transition: $transition;
		color: white;
		border-radius: $border-radius;

		.custom-line-heigth {
			line-height: 24px;
		}

		i {
			line-height: 24px;
			transform: translateY(-1px);
		}

		&.router-link-active {
			background-color: white;
			color: $primary;
			font-weight: bold;
		}

		&:hover {
			background-color: rgba($secondary, 0.7);
			color: white;
		}
	}
</style>
