<template>
	<div
		class="button d-flex align-items-center justify-content-center bg-primary text-white cursor-pointer"
		@click="action"
	>
		<div class="icon" v-if="!speeching">
			<i class="fa-solid fa-play"></i>
		</div>
		<div class="icon" v-else>
			<i class="fa-solid fa-pause"></i>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			text: {
				type: String,
				default: "",
			},
		},
		data: () => ({
			speeching: false,
		}),
		methods: {
			action() {
				if (this.speeching) {
					this.stopSpeech();
				} else {
					this.speech();
				}
			},
			speech() {
				this.speeching = true;

				const msg = new SpeechSynthesisUtterance();

				msg.text = this.text;
				msg.lang = "es";

				speechSynthesis.speak(msg);

				msg.onend = () => {
					this.speeching = false;
				};
			},
			stopSpeech() {
				this.speeching = false;
				speechSynthesis.cancel();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.button {
		height: 2rem;
		width: 2rem;
		border-radius: 50%;

		.icon {
			transform: translateY(1px);
		}
	}
</style>
