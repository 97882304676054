<template>
	<div class="flex-wrap align-items-center gap-2">
		<Level :name="challenge.level" />
		<div
			v-if="challenge.type == 'competitive'"
			class="competitive px-3 py-2 border-radius text-center"
		>
			Competitivo
		</div>
		<div v-else class="training px-3 py-2 border-radius text-center">
			Entrenamiento
		</div>
	</div>
</template>

<script>
	import Level from "@/components/partials/Level.vue";

	export default {
		components: {
			Level,
		},
		props: {
			challenge: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.competitive {
		background-color: $light-info;
		color: $info;
	}

	.training {
		background-color: $light-warning;
		color: $warning;
	}
</style>
