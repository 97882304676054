<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="orders.list"
		:total="orders.listTotal"
		:filters="filters"
		:delete="'orders'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showAddButton="false"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column header="Nº" field="id"></Column>
			<Column header="Nombre">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Avatar
							class="bg-light-primary text-white"
							:label="$helper.firstTwoLetters(slotProps.data.user.name)"
						/>

						{{ slotProps.data.user.name }}
					</div>
				</template>
			</Column>
			<Column header="Compra realizada">
				<template #body="slotProps">
					<div class="d-flex">
						<div class="quantity px-3 py-2 border-radius text-center">
							{{ slotProps.data.quantity }} pistas
						</div>
					</div>
				</template>
			</Column>
			<Column header="Total">
				<template #body="slotProps">
					{{ formatPrice(slotProps.data.price) }}
				</template>
			</Column>
			<Column header="Fecha">
				<template #body="slotProps">
					{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "user",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "price",
						value: null,
						type: "string",
						placeholder: "Precio",
					},
					{
						name: "quantity",
						value: null,
						type: "string",
						placeholder: "Compra realizada",
					},
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			formatPrice(price) {
				return price.toFixed(2) + " EUR";
			},
		},
		computed: {
			...mapState(["orders"]),
		},
	};
</script>

<style lang="scss" scoped>
	.quantity {
		background-color: $light-info;
		color: $info;
	}
</style>
