<template>
	<ProgressBar mode="indeterminate" />
</template>

<script>
	import ProgressBar from "primevue/progressbar";

	export default {
		components: {
			ProgressBar,
		},
	};
</script>
