<template>
	<Dialog
		v-model:visible="$store.state.challenges_image.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@show="show"
	>
		<div class="d-flex flex-column align-items-center gap-2">
			<div class="rounded overflow-hidden">
				<img :src="challenge.image" />
			</div>
			<Play
				v-if="challenge.image_description"
				:text="challenge.image_description"
			/>
		</div>
	</Dialog>
</template>

<script>
	import Play from "@/components/web/partials/Play.vue";
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
			Play,
		},
		props: {
			challenge: {
				type: Object,
				default: () => ({}),
			},
		},
		computed: {
			...mapState(["dialogDefaults"]),
		},
		methods: {
			...mapMutations(["toggleFormDialog"]),
		},
	};
</script>

<style lang="scss" scoped>
	img {
		width: 100%;
		min-height: 100%;
		object-fit: cover;
	}
</style>
