<template>
	<div>
		<!-- <label class="w-100 text-primary fw-bold">{{ label }}</label> -->

		<div class="custom-file-upload">
			<FileUpload
				:showUploadButton="false"
				cancelLabel="Cancelar"
				:chooseLabel="chooseLabel"
				:previewWidth="100"
				:maxFileSize="maxFileSize"
				:fileLimit="fileLimit"
				:multiple="fileLimit != null && fileLimit > 1"
				@select="selected"
				@remove="removeFile"
				@clear="removeAllFiles"
				:accept="accept"
				:disabled="disabled"
				invalidFileSizeMessage="El archivo '{0}' es demasiado grande. El tamaño máximo es de {1}.'"
				invalidFileLimitMessage="Solo se pueden subir {0} archivo/s."
			>
				<!-- <template #empty>
				<p>Arrastra tus archivos o haz clic en el botón "{{ chooseLabel }}"</p>
			</template> -->

				<template #empty>
					<div
						class="custom-border d-flex align-items-center justify-content-center flex-column"
					>
						<img src="@/assets/images/icons/drag-and-drop.png" width="150" />
						<p class="mt-2 mt-md-4 mb-0">
							Arrastra y suelta el archivo aquí para añadirlo.
						</p>
					</div>
				</template>
			</FileUpload>
		</div>

		<div class="text-danger" v-if="error != null">
			{{ error }}
		</div>

		<Button
			v-if="old_file_id != null"
			class="mt-2"
			@click="
				downloadFile({
					id: old_file_id,
					download_route: download_old_file_route,
					is_from_database,
				})
			"
			label="Descargar archivo"
		/>

		<div v-if="old_files_array.length" class="d-flex flex-wrap gap-4 py-3">
			<div
				v-for="(file, index) in old_files_array"
				:key="index"
				class="d-flex align-items-center gap-2"
			>
				<Button
					@click="
						downloadFile({
							id: file.id,
							download_route: download_old_file_route,
							is_from_database,
						})
					"
					:label="'Descargar archivo: ' + file.name"
				/>
				<i
					v-if="show_old_files_array_delete"
					class="cursor-pointer pi pi-trash text-primary"
					style="font-size: 1.2rem"
					v-tooltip.bottom="'Eliminar archivo'"
					@click="
						deleteFile({
							id: file.id,
							delete_route: delete_old_file_route,
							dispatch_action_on_delete,
						})
					"
				></i>
			</div>
		</div>
	</div>
</template>

<script>
	import FileUpload from "primevue/fileupload";

	import { mapActions } from "vuex";

	export default {
		components: {
			FileUpload,
		},
		data: () => ({
			files: [],
		}),
		props: {
			label: {
				type: String,
				default: "Seleccionar archivos",
			},
			fileLimit: {
				type: Number,
				default: 1,
			},
			maxFileSize: {
				type: Number,
				default: 10000000,
			},
			chooseLabel: {
				type: String,
				default: "Subir archivo",
			},
			accept: {
				type: String,
				default: null,
			},
			error: {
				type: String,
				default: null,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			old_file_id: {
				type: Number,
				default: null,
			},
			old_files_array: {
				type: Array,
				default: () => [],
			},
			is_from_database: {
				type: Boolean,
				default: false,
			},
			download_old_file_route: {
				type: String,
				default: null,
			},
			show_old_files_array_delete: {
				type: Boolean,
				default: false,
			},
			delete_old_file_route: {
				type: String,
				default: null,
			},
			dispatch_action_on_delete: {
				type: Object,
				default: null,
			},
		},
		methods: {
			...mapActions(["downloadFile", "deleteFile"]),
			selected(event) {
				event.files.forEach((file) => {
					this.getBase64(file);
				});
			},
			removeAllFiles() {
				this.files = [];

				const button = document.querySelector(".p-fileupload-buttonbar button");

				if (button) {
					button.click();
				}
			},
			removeFile(event) {
				this.files.forEach((file) => {
					if (file.name == event.file.name) {
						this.files.splice(this.files.indexOf(file), 1);
					}
				});
			},
			getBase64(file) {
				this.removeAllFiles();

				const reader = new FileReader();
				reader.readAsDataURL(file);

				reader.onload = () => {
					this.files.push({ name: file.name, base64: reader.result });
				};

				reader.onerror = (error) => {
					this.$toast.add({
						severity: "error",
						summary: "Ha ocurrido un error",
						detail: "Inténtelo nuevamente",
						life: 3000,
					});

					console.error("Error: ", error);
				};
			},
		},
		mounted() {
			setInterval(() => {
				const pMessageText = document.querySelector(".p-message-text");

				if (pMessageText) {
					const maxFileSize = this.maxFileSize / 1000000;
					pMessageText.textContent = `El formato no es válido o pesa demasiado (max: ${maxFileSize} MB).`;
				}
			}, 50);
		},
	};
</script>

<style lang="scss" scoped>
	.custom-file-upload {
		:deep(.p-fileupload-buttonbar) {
			display: none;
		}

		:deep(.p-fileupload-content) {
			border: none;
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23A679FFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
			border-radius: 8px;
		}
	}

	:deep(.p-fileupload-empty p) {
		margin-bottom: 0;
	}

	:deep(.p-fileupload-buttonbar) {
		gap: 1rem;
	}

	:deep(.p-fileupload-buttonbar .p-button:last-child) {
		background-color: #131313 !important;
	}

	:deep(.p-fileupload-content) {
		padding: 1.25rem;
	}

	:deep(.p-fileupload-file) {
		gap: 1rem;
	}

	:deep(.p-badge) {
		display: none;
	}

	:deep(.p-fileupload-file-thumbnail) {
		border-radius: 6px;
	}
</style>
