<template>
	<div class="container">
		<h1 class="fw-bold">Perfil</h1>
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<UserInformation />
			</div>
			<div class="col-12 col-md-6 col-lg-8 mt-5 mt-md-0">
				<Stats />
			</div>
			<div class="col-12 mt-5">
				<Historic />
			</div>
			<div class="col-12 mt-5">
				<MyChallenges />
			</div>
		</div>
	</div>
</template>

<script>
	import UserInformation from "@/components/web/profile/UserInformation.vue";
	import Stats from "@/components/web/profile/Stats.vue";
	import Historic from "@/components/web/profile/Historic.vue";
	import MyChallenges from "@/components/web/profile/MyChallenges.vue";

	export default {
		components: {
			UserInformation,
			Stats,
			Historic,
			MyChallenges,
		},
	};
</script>
