<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="users.list"
		:total="users.listTotal"
		:filters="filters"
		:delete="'users'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir usuario'"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column header="Nº" field="code"></Column>
			<Column header="Nickname">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Avatar
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-white"
							:label="$helper.firstTwoLetters(slotProps.data.name)"
						/>

						{{ slotProps.data.name }}
					</div>
				</template>
			</Column>
			<Column header="Email" field="email"></Column>
			<Column header="Rol">
				<template #body="slotProps">
					<div class="d-flex">
						<div
							v-if="slotProps.data.role_id == 1"
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-secondary px-3 py-2 text-white border-radius"
						>
							{{ slotProps.data.role.slug }}
						</div>
						<div
							v-else
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-white px-3 py-2 border-radius"
						>
							{{ slotProps.data.role.slug }}
						</div>
					</div>
				</template>
			</Column>
			<Column header="Pistas">
				<template #body="slotProps">
					<InputNumber
						v-model="slotProps.data.hints"
						inputId="horizontal-buttons"
						showButtons
						buttonLayout="horizontal"
						:step="1"
						incrementButtonIcon="pi pi-plus"
						decrementButtonIcon="pi pi-minus"
						:min="0"
						@update:modelValue="setHints(slotProps.data.id, $event)"
					/>
				</template>
			</Column>
			<Column header="Fecha">
				<template #body="slotProps">
					{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
				</template>
			</Column>
			<Column header="Acciones">
				<template #body="slotProps">
					<Button class="table-custom-button-action d-flex gap-3">
						<img
							src="@/assets/images/icons/eye.png"
							@click="showRegister(slotProps.data, 'edit', 'info')"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'update')"
							src="@/assets/images/icons/edit.png"
							@click="showRegister(slotProps.data, 'edit')"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/block.png"
							@click="deleteRegister(slotProps.data.id, slotProps.data.active)"
							:class="[slotProps.data.active ? '' : 'not-active']"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import InputNumber from "primevue/inputnumber";

	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
			InputNumber,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "name",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Email",
					},
					{
						name: "role",
						value: null,
						type: "dropdown",
						placeholder: "Rol",
						options: [
							{
								label: "Administrador",
								value: "super_admin",
							},
							{
								label: "Cliente",
								value: "customer",
							},
						],
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
				],
				timeout: null,
				lastUserId: null,
				lastEvent: null,
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList", "updateHints"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					// this.changeFormDialogMode({
					// 	stateVariable: this.stateVariable,
					// 	dialogMode: type,
					// });

					this.toggleInfoDialog(true);
				}
			},
			deleteRegister(id, active) {
				const lastPageChange = this.$refs.table.lastPageChange;

				this.$confirm.require({
					message: active
						? "¿Desea bloquear el usuario?"
						: "¿Desea desbloquear el usuario?",
					header: "Cambiar estado",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/users/${id}`,
						}).then(() => {
							this.$refs.table.getList(lastPageChange);
						});
					},
				});
			},
			setHints(user_id, event) {
				this.$nextTick(() => {
					if (this.lastUserId == user_id && this.lastEvent == event) return;

					this.lastUserId = user_id;
					this.lastEvent = event;

					const lastPageChange = this.$refs.table.lastPageChange;
					clearTimeout(this.timeout);

					this.timeout = setTimeout(() => {
						this.updateHints({
							user_id,
							hints: event,
						}).then(() => {
							this.$refs.table.getList(lastPageChange);
						});
					}, 500);
				});
			},
		},
		computed: {
			...mapState(["users"]),
		},
	};
</script>

<style lang="scss" scoped>
	.not-active {
		filter: grayscale(100%);
	}

	:deep(.p-inputnumber-buttons-horizontal .p-inputnumber-input) {
		max-width: 5rem;
		text-align: center;
	}
</style>
