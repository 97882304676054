<template>
	<div class="navbar w-100 p-0">
		<div class="container d-flex justify-content-between align-items-stretch">
			<div class="logo-section">
				<div
					class="logo d-flex justify-content-center align-items-center bg-white box-shadow"
				>
					<router-link to="/">
						<img src="@/assets/images/black-logo.png" alt="Logo" width="80" />
					</router-link>
				</div>
			</div>
			<div v-if="userLogged" class="scale-down d-flex gap-3 py-3">
				<div
					class="data d-flex align-items-center gap-3 border-radius text-white px-3 py-2"
				>
					<img src="@/assets/images/web/hints.png" width="20" />
					<span>Pistas restantes: {{ userLogged.hints }}</span>
				</div>
				<div
					class="data d-flex align-items-center gap-3 border-radius text-white px-3 py-2"
				>
					<img src="@/assets/images/web/level.png" width="20" />
					<span
						>Nivel actual:
						{{
							userLogged.user_level != null ? userLogged.user_level.level : 0
						}}</span
					>
				</div>
				<Button
					label="Comprar pistas"
					class="bg-white text-primary"
					@click="
						toggleFormDialog({
							stateVariable: 'buy_hints',
							show: true,
						})
					"
				/>
			</div>
			<div class="scale-down d-flex align-items-center gap-3 py-3">
				<div
					class="info d-flex align-items-center justify-content-center border-radius cursor-pointer"
					@click="
						toggleFormDialog({
							stateVariable: 'info',
							show: true,
						})
					"
				>
					<i class="pi pi-info-circle text-white" style="font-size: 1.2rem"></i>
				</div>
				<CurrentUser
					v-if="userLogged"
					@click="$router.push({ name: 'Perfil' })"
					class="cursor-pointer"
				/>
				<i
					v-if="userLogged"
					@click="logout()"
					class="pi pi-sign-out cursor-pointer text-white"
					style="transform: rotate(180deg)"
					v-tooltip.bottom="'Cerrar sesión'"
				></i>
				<Button
					v-else
					@click="$router.push({ name: 'login' })"
					icon="pi pi-sign-in"
					label="Iniciar sesión"
					iconPos="right"
					class="bg-white text-primary"
				></Button>
			</div>
		</div>
		<Info />
		<BuyHints />
	</div>
</template>

<script>
	import CurrentUser from "@/components/web/partials/CurrentUser.vue";
	import Info from "@/components/web/partials/Info.vue";
	import BuyHints from "@/components/web/challenge/BuyHints.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			CurrentUser,
			Info,
			BuyHints,
		},
		computed: {
			...mapState(["userLogged"]),
		},
		methods: {
			...mapActions(["logout"]),
			...mapMutations(["toggleFormDialog"]),
		},
	};
</script>

<style lang="scss" scoped>
	.navbar {
		background: linear-gradient(90deg, #1e0969 -8.93%, #9747ff 102.98%);
		$logo-size: 100px;

		.logo-section {
			position: relative;
			width: $logo-size;

			.logo {
				position: absolute;
				border-bottom-left-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
				height: $logo-size;
				width: $logo-size;
			}
		}

		.data {
			background-color: rgba($dark, 0.5);
		}

		.info {
			width: 50px;
			height: 50px;
			border: 2px solid white;
		}

		@media (min-width: $tablet-min-width) and (max-width: $large-tablet-max-width) {
			.scale-down {
				transform: scale(0.8);
			}
		}
	}
</style>
