<template>
	<div class="container">
		<h3 class="fw-bold">Mis retos</h3>
		<div
			v-if="
				profileStats.userLevel == null || profileStats.userLevel?.points < 200
			"
			class="banner w-100 d-flex justify-content-center p-3 bg-light-secondary py-3 py-md-5"
		>
			<div class="d-flex flex-column align-items-center gap-3">
				<img src="@/assets/images/web/blocked.png" alt="Logo" width="50" />
				<div class="col-12 col-md-8">
					<p class="text-dark fw-bold text-center">
						Necesitas 200 puntos para poder crear tus propios retos.
					</p>
					<p class="text-dark text-center mb-0">
						Continúa superando retos para poder alcanzar los puntos necesarios y
						poder retar a otros jugadores con los tuyos propios.
					</p>
				</div>
			</div>
		</div>
		<div v-if="profileStats.userLevel?.points >= 200" class="row">
			<div
				v-for="challenge in challengesList"
				:key="challenge.id"
				class="col-12 col-md-6 col-lg-3 mt-4"
			>
				<Challenge
					:id="challenge.id"
					:image="challenge.image"
					:showStatus="false"
					:name="challenge.name"
					:category="challenge.category.name"
					:difficulty="challenge.level"
					:date="
						challenge.type == 'competitive'
							? $helper.formatDate(challenge.date, 'DD/MM/YYYY')
							: ''
					"
					:showAddHintsButton="true"
					:showPending="!challenge.published"
					@add-hints="addHints($event)"
				/>
			</div>
			<div class="col-12 col-md-6 col-lg-3 mt-4">
				<AddChallenge @click="addChallenge()" />
			</div>
		</div>
	</div>
	<ChallengesForm
		route="challenges"
		stateVariable="challenges"
		@update-challenges="onUpdateChallenges"
	/>
	<HintsForm route="hints" stateVariable="hints" :challengeId="challengeId" />
</template>

<script>
	import Challenge from "@/components/web/partials/Challenge.vue";
	import AddChallenge from "@/components/web/partials/AddChallenge.vue";
	import ChallengesForm from "@/components/challenges/ChallengesForm.vue";
	import HintsForm from "@/components/challenges/hints/HintsForm.vue";

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Challenge,
			AddChallenge,
			ChallengesForm,
			HintsForm,
		},
		data() {
			return {
				challengeId: null,
			};
		},
		methods: {
			...mapActions(["getCreatedUserChallenges"]),
			...mapMutations(["toggleFormDialog", "clearChallengesList"]),
			addChallenge() {
				this.toggleFormDialog({
					stateVariable: "challenges",
					show: true,
				});
			},
			onUpdateChallenges() {
				this.getCreatedUserChallenges();
			},
			addHints(challengeId) {
				this.challengeId = challengeId;

				this.toggleFormDialog({
					stateVariable: "hints",
					show: true,
				});
			},
		},
		mounted() {
			this.getCreatedUserChallenges();
		},
		computed: {
			...mapState(["challengesList", "profileStats"]),
		},
		beforeUnmount() {
			this.clearChallengesList();
		},
	};
</script>

<style lang="scss" scoped>
	.banner {
		background-image: url("@/assets/images/web/question-icons.png");
		background-position: center;
		background-size: cover;
		border-radius: 20px;
		border: 1px solid $light-primary;

		.challenges-icon {
			width: 7rem;
			border-radius: 20px;
			aspect-ratio: 1;
		}
	}
</style>
