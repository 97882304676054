<template>
	<div>
		<OrdersTable :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import OrdersTable from "./OrdersTable.vue";

	export default {
		components: {
			OrdersTable,
		},
		data() {
			return {
				route: "orders",
				stateVariable: "orders",
			};
		},
	};
</script>
