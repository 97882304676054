<template>
	<div>
		<LogsTable :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import LogsTable from "./LogsTable.vue";

	export default {
		components: {
			LogsTable,
		},
		data() {
			return {
				route: "logs",
				stateVariable: "logs",
			};
		},
	};
</script>
