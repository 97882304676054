<template>
	<div class="container">
		<div
			class="banner w-100 d-flex justify-content-center p-3 bg-light-secondary"
		>
			<div class="d-flex flex-column align-items-center gap-3">
				<div v-if="challenge.image" class="banner-image cursor-pointer">
					<img
						:src="challenge.image"
						alt="Logo"
						width="150"
						@click="
							toggleFormDialog({
								stateVariable: 'challenges_image',
								show: true,
							})
						"
					/>
				</div>
				<h1 class="mb-0 text-dark fw-bold text-center">{{ challenge.name }}</h1>
			</div>
		</div>
		<div class="d-flex justify-content-center">
			<div class="col-12 col-md-6">
				<div
					class="d-flex align-items-center justify-content-center gap-2 mt-3"
				>
					<Level :name="challenge.category.name" />
					<Level :name="challenge.level" />
				</div>
				<div
					class="d-flex flex-column justify-content-center align-items-center gap-2 mt-3"
				>
					<p class="fw-bold text-center lh-1 mb-0 white-space-pre-wrap">
						{{ challenge.statement }}
					</p>
					<Play :text="challenge.statement" />
				</div>
				<div
					v-if="
						showActions && challenge.hints.length > 0 && userLogged?.hints > 0
					"
					class="d-flex justify-content-center align-items-center gap-2 mt-3"
				>
					<div
						class="use-hints bg-light-warning d-flex justify-content-center align-items-center border-radius"
						:class="[allHintsSolved ? 'opacity-50' : 'cursor-pointer']"
						@click="useHints()"
					>
						<img src="@/assets/images/icons/hint.png" />
					</div>
					<div class="fw-bold text-dark">Pedir pista</div>
				</div>
				<div v-if="showActions" class="mt-3">
					<label for="input" class="text-primary fw-bold">Solución</label>

					<InputText
						v-model="solution"
						placeholder="Solución"
						type="text"
						class="w-100"
					/>

					<div v-if="answerError != null" class="small text-danger">
						{{ answerError }}
					</div>
				</div>
				<div v-if="showActions" class="row mt-3">
					<div class="col-12 col-md-6 mb-3 mb-md-0">
						<Button label="Responder" class="w-100" @click="answer" />
					</div>
					<div class="col-12 col-md-6">
						<Button label="Abandonar" class="bg-danger w-100" @click="quit" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Play from "@/components/web/partials/Play.vue";
	import Level from "@/components/partials/Level.vue";
	import InputText from "primevue/inputtext";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Play,
			Level,
			InputText,
		},
		props: {
			challenge: {
				type: Object,
				default: () => ({}),
			},
		},
		data: () => ({
			solution: null,
			answerError: null,
		}),
		methods: {
			...mapActions(["challengeSolved"]),
			...mapMutations(["toggleFormDialog"]),
			answer() {
				this.answerError = null;

				if (this.solution == null) {
					this.answerError = "Debes ingresar una respuesta";
					return;
				}

				if (this.solution == "") {
					this.answerError = "Debes ingresar una respuesta";
					return;
				}

				const challengeSolutions = this.challenge.solution.split("@");
				let solved = false;

				challengeSolutions.forEach((challengeSolution) => {
					if (this.solution === challengeSolution.trim()) {
						solved = true;
					}
				});

				if (solved) {
					this.toggleFormDialog({
						stateVariable: "challenges_success",
						show: true,
					});

					if (this.userLogged) {
						this.challengeSolved({
							challenge_id: this.$route.params.id,
						});
					}
				} else {
					this.toggleFormDialog({
						stateVariable: "challenges_error",
						show: true,
					});
				}
			},
			quit() {
				this.toggleFormDialog({
					stateVariable: "challenges_quit",
					show: true,
				});
			},
			useHints() {
				if (!this.allHintsSolved) {
					this.toggleFormDialog({
						stateVariable: "use_hints",
						show: true,
					});
				}
			},
		},
		computed: {
			...mapState(["userLogged", "allHintsSolved"]),
			showActions() {
				if (this.userLogged) {
					return this.challenge.user_has_challenges.length > 0
						? this.challenge.user_has_challenges[0].status != "quitted"
						: false;
				} else {
					return true;
				}
			},
		},
		watch: {
			solution: {
				handler: function (val) {
					this.solution = val.trim();
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.banner {
		background-image: url("@/assets/images/web/question-icons.png");
		background-position: center;
		background-size: cover;
		border-radius: 20px;

		.banner-image {
			border-radius: 20px;
			overflow: hidden;
		}
	}

	.use-hints {
		width: 3rem;
		height: 3rem;
	}
</style>
