<template>
	<div class="stat rounded box-shadow bg-light-secondary px-4 pt-5 pb-4">
		<div
			class="top-icon bg-primary rounded d-flex justify-content-center align-items-center"
			:class="[isExperience ? 'experience' : '']"
		>
			<img :src="getImgUrl(image)" width="20" />
		</div>
		<div class="d-flex align-items-end gap-3">
			<div v-if="showKnob" class="current-level">
				<Knob v-model="amount" :strokeWidth="5" readonly :min="0" :max="100" />
				<div class="current-level-text">Nivel</div>
			</div>
			<div class="d-flex flex-column align-items-center gap-2 w-100">
				<div class="h5 mb-0 fw-bold text-dark text-center">
					{{ title }}
				</div>
				<div class="d-flex align-items-center gap-2 w-100">
					<div
						class="w-100 bg-white rounded d-flex justify-content-center py-2"
					>
						<div class="text-light-primary h1 mb-0 fw-bold">{{ value }}</div>
					</div>
					<Button
						v-if="showButton"
						label="IR"
						@click="
							$router.push({
								name: 'Ranking',
							})
						"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Knob from "primevue/knob";

	export default {
		components: {
			Knob,
		},
		props: {
			title: {
				type: String,
				default: null,
			},
			value: {
				type: [String, Number],
				default: null,
			},
			showKnob: {
				type: Boolean,
				default: false,
			},
			showButton: {
				type: Boolean,
				default: false,
			},
			knobAmount: {
				type: Number,
				default: 0,
			},
			image: {
				type: String,
				default: "experience-points.png",
			},
		},
		data() {
			return {
				amount: null,
			};
		},
		methods: {
			getImgUrl(pic) {
				return require("@/assets/images/web/" + pic);
			},
		},
		mounted() {
			this.amount = this.knobAmount;
		},
		computed: {
			isExperience() {
				return this.title === "Puntos de experiencia";
			},
		},
	};
</script>

<style lang="scss" scoped>
	.stat {
		position: relative;

		.top-icon {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 3rem;
			aspect-ratio: 1;

			&.experience {
				left: 5rem;
			}
		}

		.current-level {
			position: relative;
			transform: translateY(6px) scale(1.2);

			&-text {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -130%);
				text-transform: uppercase;
				color: $primary;
				font-size: 0.7rem;
			}

			:deep(.p-knob-range) {
				stroke: white;
			}

			:deep(.p-knob-value) {
				stroke: $info;
				stroke-width: 8px;
			}

			:deep(.p-knob-text) {
				font-size: 2rem;
				transform: translateY(13px);
				fill: #31129c;
				font-weight: bold;
			}
		}
	}
</style>
