<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="logs.list"
		:total="logs.listTotal"
		:filters="filters"
		:delete="'logs'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showAddButton="false"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column header="Nº" field="id"></Column>
			<Column header="Nombre">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Avatar
							class="bg-light-primary text-white"
							:label="$helper.firstTwoLetters(slotProps.data.user.name)"
						/>

						{{ slotProps.data.user.name }}
					</div>
				</template>
			</Column>
			<Column header="Actividad">
				<template #body="slotProps">
					<div class="d-flex">
						<div
							v-if="slotProps.data.type == 'Login'"
							class="quantity px-3 py-2 border-radius text-center"
						>
							Inicio sesión
						</div>
						<div
							v-else-if="slotProps.data.type == 'Challenge started'"
							class="challenge-started px-3 py-2 border-radius text-center"
						>
							Reto iniciado
						</div>
						<div
							v-else-if="slotProps.data.type == 'Challenge solved'"
							class="challenge-solved px-3 py-2 border-radius text-center"
						>
							Reto terminado
						</div>
						<div
							v-else-if="slotProps.data.type == 'Challenge quitted'"
							class="challenge-quitted px-3 py-2 border-radius text-center"
						>
							Reto abandonado
						</div>
					</div>
				</template>
			</Column>
			<Column header="Fecha">
				<template #body="slotProps">
					{{
						$helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY HH:mm")
					}}
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "user",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "type",
						value: null,
						type: "dropdown",
						placeholder: "Actividad",
						options: [
							{
								label: "Inicio sesión",
								value: "Login",
							},
							{
								label: "Reto iniciado",
								value: "Challenge started",
							},
							{
								label: "Reto terminado",
								value: "Challenge solved",
							},
							{
								label: "Reto abandonado",
								value: "Challenge quitted",
							},
						],
					},
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			formatPrice(price) {
				return price.toFixed(2) + " EUR";
			},
		},
		computed: {
			...mapState(["logs"]),
		},
	};
</script>

<style lang="scss" scoped>
	.quantity {
		background-color: $light-info;
		color: $info;
	}

	.challenge-started {
		background-color: $light-warning;
		color: $warning;
	}

	.challenge-solved {
		background-color: $light-success;
		color: $success;
	}

	.challenge-quitted {
		background-color: $light-danger;
		color: $danger;
	}
</style>
