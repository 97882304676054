<template>
	<div
		class="podium-step-content d-flex flex-column align-items-center justify-content-end gap-3"
	>
		<div
			class="d-flex flex-column align-items-center justify-content-start gap-2"
		>
			<div
				class="layer-section"
				:class="{
					'step-2': step == 2,
					'step-3': step == 3,
				}"
			>
				<div class="layer layer-1"></div>
				<div class="layer layer-2"></div>
				<div class="layer layer-3"></div>
				<div class="initials text-primary">
					{{ $helper.firstTwoLetters(name) }}
				</div>
				<div class="medal">
					<img
						v-if="step == 1"
						src="@/assets/images/web/gold-medal.png"
						width="50"
					/>
					<img
						v-else-if="step == 2"
						src="@/assets/images/web/silver-medal.png"
						width="50"
					/>
					<img
						v-else-if="step == 3"
						src="@/assets/images/web/bronze-medal.png"
						width="50"
					/>
				</div>
			</div>
			<div class="text-center fw-bold text-dark">{{ name }}</div>
			<div
				class="points rounded border border-2 px-3 py-2 bg-light-secondary fw-bold text-center"
				:class="{
					'border-primary text-primary': step == 1,
					'border-secondary text-secondary': step == 2,
					'border-light-primary text-light-primary': step == 3,
				}"
			>
				{{ pointsText }}
			</div>
		</div>
		<div
			class="podium-step rounded p-2 p-md-3 text-white text-center w-100"
			:class="{
				'bg-primary podium-step-1': step == 1,
				'bg-secondary podium-step-2': step == 2,
				'bg-light-primary podium-step-3': step == 3,
			}"
		>
			{{ step }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
				required: true,
			},
			points: {
				type: Number,
				required: true,
			},
			step: {
				type: Number,
				required: true,
			},
		},
		computed: {
			pointsText() {
				return this.points + " Ptos";
			},
		},
	};
</script>

<style lang="scss" scoped>
	$layer-size: 8rem;

	.podium-step-content {
		max-width: $layer-size;
		height: 30rem;

		.podium-step {
			font-size: 5rem;
			font-weight: bold;
			line-height: 5rem;
			transition: $transition;

			&-1 {
				animation: grow-up-step-1 2s ease-out forwards;

				@keyframes grow-up-step-1 {
					from {
						height: 5rem;
					}

					to {
						height: 15rem;
					}
				}
			}

			&-2 {
				animation: grow-up-step-2 2s ease-out forwards;
				@keyframes grow-up-step-2 {
					from {
						height: 5rem;
					}

					to {
						height: 12rem;
					}
				}
			}

			&-3 {
				animation: grow-up-step-3 2s ease-out forwards;
				@keyframes grow-up-step-3 {
					from {
						height: 5rem;
					}

					to {
						height: 9rem;
					}
				}
			}
		}

		.layer-section {
			position: relative;
			width: $layer-size;
			height: $layer-size;
			transform-origin: bottom;

			.layer {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				aspect-ratio: 1;

				&-1 {
					width: $layer-size;
					background-color: $primary;
				}

				&-2 {
					width: calc($layer-size - 1rem);
					background-color: white;
				}

				&-3 {
					width: calc($layer-size - 2rem);
					background-color: $light-secondary;
				}
			}

			.initials {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-transform: uppercase;
				font-size: 2rem;
				font-weight: bold;
			}

			.medal {
				position: absolute;
				top: -1rem;
				right: -1rem;
			}

			&.step-2 {
				transform: scale(0.9);
			}

			&.step-3 {
				transform: scale(0.8);
			}
		}
	}

	@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		$layer-size: calc((100vw - 5rem) / 3);

		.podium-step-content {
			max-width: $layer-size;

			.podium-step {
				font-size: 3rem;
				line-height: 3rem;

				&-1 {
					@keyframes grow-up-step-1 {
						from {
							height: 2rem;
						}

						to {
							height: 10rem;
						}
					}
				}

				&-2 {
					@keyframes grow-up-step-2 {
						from {
							height: 2rem;
						}

						to {
							height: 7rem;
						}
					}
				}

				&-3 {
					@keyframes grow-up-step-3 {
						from {
							height: 2rem;
						}

						to {
							height: 4rem;
						}
					}
				}
			}

			.layer-section {
				width: $layer-size;
				height: $layer-size;

				.layer {
					&-1 {
						width: $layer-size;
					}

					&-2 {
						width: calc($layer-size - 0.5rem);
					}

					&-3 {
						width: calc($layer-size - 1rem);
					}
				}

				.initials {
					font-size: 1.5rem;
				}

				.medal {
					img {
						width: 40px;
					}
				}
			}

			.points {
				display: flex;
				flex-direction: column;
			}
		}
	}
</style>
