<template>
	<div
		class="user-information d-flex flex-column rounded box-shadow overflow-hidden"
	>
		<div class="top-section"></div>
		<div class="initials">
			<div class="ellipse ellipse-1"></div>
			<div class="ellipse ellipse-2"></div>
			<div class="ellipse ellipse-3"></div>
			<div
				class="ellipse ellipse-4 d-flex justify-content-center align-items-center text-primary fw-bold h5 mb-0"
			>
				{{ $helper.firstTwoLetters(userLogged.name) }}
			</div>
		</div>
		<div
			class="bottom-section d-flex flex-column align-items-center justify-content-center gap-2"
		>
			<h5 class="user-name fw-bold mb-0">
				<InputText
					class="user-name-input"
					type="text"
					v-model="name"
					placeholder="Nickname"
				/>
				<div
					class="edit bg-white d-flex justify-content-center align-items-center box-shadow rounded-circle cursor-pointer"
					@click="onUpdate()"
					v-tooltip.right="'Guardar'"
				>
					<i class="pi pi-pencil text-info"></i>
				</div>
			</h5>
			<div class="email">
				<div class="text-muted">{{ userLogged.email }}</div>
			</div>
			<Button
				label="Modificar contraseña"
				@click="toggleUpdatePasswordDialog(true)"
			/>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapMutations, mapState } from "vuex";
	import InputText from "primevue/inputtext";

	export default {
		components: {
			InputText,
		},
		data() {
			return {
				name: null,
				timeout: null,
			};
		},
		methods: {
			...mapActions(["updateProfileName"]),
			...mapMutations([
				"toggleUpdatePasswordDialog",
				"changeUserLoggedName",
				"changeErrorToast",
			]),
			onUpdate() {
				const name = this.name.trim();

				this.updateProfileName({
					name,
				}).then((response) => {
					if (response.status == 200) {
						this.changeUserLoggedName(this.name);
					}
				});
			},
		},
		mounted() {
			this.name = this.userLogged.name;
		},
		computed: {
			...mapState(["userLogged"]),
		},
	};
</script>

<style lang="scss" scoped>
	$user-information-sections-height: 18rem;
	$user-information-ellipses-size: 115px;
	.user-information {
		position: relative;

		.top-section {
			height: $user-information-sections-height;
			background: linear-gradient(90deg, #1e0969 -8.93%, #9747ff 102.98%);
		}

		.initials {
			.ellipse {
				border-radius: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				aspect-ratio: 1;
				background: white;

				&-1 {
					width: $user-information-ellipses-size;
					z-index: 5;
				}

				&-2 {
					width: calc(#{$user-information-ellipses-size} - 15px);
					background: linear-gradient(90deg, #1e0969 -8.93%, #9747ff 102.98%);
					z-index: 6;
				}

				&-3 {
					width: calc(#{$user-information-ellipses-size} - 25px);
					z-index: 7;
				}

				&-4 {
					width: calc(#{$user-information-ellipses-size} - 35px);
					background: $light-secondary;
					z-index: 8;
				}
			}
		}

		.bottom-section {
			height: $user-information-sections-height;
			background-color: white;

			.user-name {
				position: relative;
				max-width: 70%;
				min-width: $user-information-ellipses-size;

				.user-name-input {
					text-align: center;
				}

				.edit {
					position: absolute;
					top: -2.5rem;
					right: -2.5rem;
					width: 40px;
					height: 40px;
					transition: $transition;

					&:hover {
						transform: translateY(2px);
						background-color: darken(white, 8%) !important;
					}
				}
			}

			.email {
				max-width: 90%;
				font-size: 0.9rem;

				div {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
</style>
