<template>
	<Dialog
		v-model:visible="$store.state.challenges_error.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@show="show"
	>
		<template #header>
			<FormTitle :title="challenge.name" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12 d-flex justify-content-center">
					<img src="@/assets/images/web/challenges-error.png" width="150" />
				</div>
				<div
					class="col-12 d-flex flex-column align-items-center justify-content-center"
				>
					<p class="text-dark fw-bold h5 mb-3">¡Ups...!</p>
					<p class="text-dark fw-bold mb-3">La respuesta es incorrecta...</p>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-center">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: 'challenges_error',
							show: false,
						})
					"
					label="Cerrar"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import { mapMutations, mapState } from "vuex";

	export default {
		props: {
			challenge: {
				type: Object,
				default: () => ({}),
			},
		},
		computed: {
			...mapState(["dialogDefaults"]),
		},
		methods: {
			...mapMutations(["toggleFormDialog"]),
		},
	};
</script>
