<template>
	<div class="footer py-3">
		<div
			class="container d-flex flex-column flex-md-row justify-content-between align-items-center"
		>
			<div>2023&copy;&nbsp;<span class="fw-bold">El Reto de Kike</span></div>
			<!-- <div class="d-flex gap-4">
				<div>Acerca de</div>
				<div>Soporte</div>
			</div> -->
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.footer {
		border-top: 1px solid $light-grey;
	}
</style>
