<template>
	<div class="row gy-5">
		<div v-if="profileStats.userLevel != null" class="col-12 col-lg-8">
			<Stat
				title="Puntos de experiencia"
				:value="profileStats.userLevel.points"
				:showKnob="true"
				:knobAmount="profileStats.userLevel.level"
				image="experience-points.png"
			/>
		</div>
		<div v-if="profileStats.myRank != null" class="col-12 col-lg-4">
			<Stat
				title="Ranking"
				:value="`#${profileStats.myRank}`"
				:showButton="true"
				image="ranking.png"
			/>
		</div>
		<div v-if="profileStats.challengesSolved != null" class="col-12 col-lg-4">
			<Stat
				title="Retos completos"
				:value="profileStats.challengesSolved"
				image="challenges.png"
			/>
		</div>
		<div class="col-12 col-lg-4">
			<Stat
				title="Retos incompletos"
				:value="profileStats.challengesStarted"
				image="challenges.png"
			/>
		</div>
		<div class="col-12 col-lg-4">
			<Stat
				title="Pistas restantes"
				:value="userLogged.hints"
				image="hints.png"
			/>
		</div>
		<div class="col-12 mt-4">
			<div
				class="rounded box-shadow bg-light-secondary p-4 small text-dark fw-bold text-center text-lg-start"
			>
				Si te ha gustado esta página y quieres contribuir económicamente a que
				se mantenga abierta, puedes usar la herramienta de “Comprar pistas” para
				ayudarnos. Muchísimas gracias.
			</div>
		</div>
	</div>
</template>

<script>
	import Stat from "@/components/web/profile/Stat.vue";
	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Stat,
		},
		methods: {
			...mapActions(["getProfileStats"]),
		},
		mounted() {
			this.getProfileStats();
		},
		computed: {
			...mapState(["profileStats", "userLogged"]),
		},
	};
</script>
