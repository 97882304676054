<template>
	<div class="card p-3">
		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3">
				<div class="col-12 d-flex justify-content-center">
					<div class="border-radius overflow-hidden">
						<img v-if="adImage != null" class="image" :src="adImage" />
						<img v-else src="@/assets/images/no-image.png" class="image" />
					</div>
				</div>
				<div class="col-12 d-flex align-items-center flex-column gap-3">
					<FileUpload
						class="col-12 col-md-6"
						label="Actualizar"
						ref="FileUpload"
						:error="form.errors.get('files_list')"
						:disabled="disabled"
						:accept="'image/*'"
						:maxFileSize="3000000"
					/>
					<Button @click="save()" label="Actualizar" :disabled="form.busy" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		data: () => ({
			form: new Form(),
			disabled: false,
			adImage: null,
		}),
		methods: {
			...mapActions(["sendForm", "getAdd"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const url = `/ads`;

				this.form.files_list = this.$refs.FileUpload.files;

				if (this.form.files_list.length > 0) {
					this.sendForm({
						method: "post",
						form: this.form,
						url: url,
						errors: this.form.errors,
					}).then(() => {
						this.clearForm();
						this.get();
					});
				} else {
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail: "Debe seleccionar una imagen.",
						life: 3000,
					});
				}
			},
			clearForm() {
				this.$refs.FileUpload.removeAllFiles();
			},
			get() {
				this.getAdd().then((response) => {
					this.adImage = response.data.adImage;
				});
			},
		},
		computed: {
			...mapState(["ads"]),
		},
		mounted() {
			this.get();
		},
	};
</script>

<style lang="scss" scoped>
	.image {
		max-width: 50vw;
		max-height: 30vh;
		min-width: 10rem;
		min-height: 10rem;
	}
</style>
