<template>
	<div :class="{ 'd-none': ranking.list?.length == 0 }">
		<DataTable :value="ranking.list" class="ranking-table w-100">
			<Column header="Pole" class="align-column-header" style="width: 5rem">
				<template #body="slotProps">
					<div class="d-flex justify-content-center">
						<div
							class="position d-flex justify-content-center align-items-center text-dark border border-1 border-light-primary"
						>
							{{ slotProps.data.position }}
						</div>
					</div>
				</template>
			</Column>
			<Column header="Nombre">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-3">
						<div
							class="initials bg-light-secondary text-primary rounded d-flex justify-content-center align-items-center fw-bold"
						>
							{{ $helper.firstTwoLetters(slotProps.data.user.name) }}
						</div>
						<span class="fw-bold text-dark">{{
							slotProps.data.user.name
						}}</span>
					</div>
				</template>
			</Column>
			<Column header="Nivel" class="align-column-header">
				<template #body="slotProps">
					<div class="d-flex justify-content-center">
						<div class="bg-light-info text-info px-3 py-2 border-radius">
							Nivel <b>{{ slotProps.data.level }}</b>
						</div>
					</div>
				</template>
			</Column>
			<Column header="Retos completados" class="align-column-header">
				<template #body="slotProps">
					<div class="d-flex justify-content-center">
						{{ slotProps.data.user.user_has_challenges.length }} retos
					</div>
				</template>
			</Column>
			<Column header="Puntos" class="align-column-header">
				<template #body="slotProps">
					<div class="d-flex justify-content-center">
						<div
							class="bg-light-secondary text-secondary px-3 py-2 border-radius"
						>
							{{ slotProps.data.points }} Ptos
						</div>
					</div>
				</template>
			</Column>
		</DataTable>
		<div
			class="d-flex flex-column flex-md-row justify-content-between mt-2 mt-md-3"
		>
			<Paginator
				class="ranking-paginator w-100"
				:rows="rows"
				:totalRecords="ranking.listTotal"
				:rowsPerPageOptions="[rows, 20, 30]"
				template="PrevPageLink PageLinks NextPageLink JumpToPageDropdown CurrentPageReport"
				currentPageReportTemplate="Mostrando {first} de {totalRecords} resultados"
				@page="changeCurrentPage($event)"
			/>
			<!-- <Paginator
				class="ranking-paginator mt-3 mt-md-0"
				:rows="rows"
				:totalRecords="ranking.listTotal"
				:rowsPerPageOptions="[rows, 20, 30]"
				template="JumpToPageDropdown CurrentPageReport"
				currentPageReportTemplate="Mostrando {first} de {totalRecords} resultados"
				@page="changeCurrentPage($event)"
			/> -->
		</div>
	</div>
</template>

<script>
	import DataTable from "primevue/datatable";
	import Column from "primevue/column";
	import Paginator from "primevue/paginator";
	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			DataTable,
			Column,
			Paginator,
		},
		props: {
			rows: {
				type: Number,
				default: 10,
			},
		},
		data() {
			return {};
		},
		methods: {
			...mapActions(["getRanking"]),
			changeCurrentPage(event) {
				this.getRanking({
					page: event?.page,
					rows: event?.rows,
				});
			},
			moveToDiv(parentClass, elementClass) {
				const parent = document.querySelector(
					`.ranking-paginator ${parentClass}`
				);

				const child = document.querySelector(
					`.ranking-paginator ${elementClass}`
				);

				parent.appendChild(child);
			},
		},
		computed: {
			...mapState(["ranking"]),
		},
		mounted() {
			const paginator = document.querySelector(
				".ranking-paginator .p-paginator.p-component"
			);

			const leftSection = document.createElement("div");

			leftSection.classList.add("left-section");

			paginator.appendChild(leftSection);

			this.moveToDiv(".left-section", ".p-paginator-prev");
			this.moveToDiv(".left-section", ".p-paginator-pages");
			this.moveToDiv(".left-section", ".p-paginator-next");

			const rightSection = document.createElement("div");

			rightSection.classList.add("right-section");

			paginator.appendChild(rightSection);

			this.moveToDiv(".right-section", ".p-dropdown");
			this.moveToDiv(".right-section", ".p-paginator-current");
		},
	};
</script>

<style lang="scss" scoped>
	.position {
		height: 3rem;
		width: 3rem;
		border-radius: 50%;
		background-color: var(--primary);
		color: var(--white);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
	}

	.initials {
		height: 3rem;
		width: 3rem;
	}

	.ranking-table {
		border-radius: unset;
		overflow: visible;
		box-shadow: none;
		border-top: none;

		:deep(.p-datatable-thead) {
			th {
				background: unset;
				border: none;
				background-color: #f3f6f9;
				color: $dark-grey;
				text-transform: uppercase;

				&:first-of-type {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}

				&:last-of-type {
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}

				&.align-column-header {
					.p-column-header-content {
						justify-content: center;
					}
				}
			}
		}

		:deep(.p-datatable-tbody) {
			tr {
				position: relative;
				background: transparent;

				&::after {
					content: "";
					position: absolute;
					top: 0.4rem;
					left: 0rem;
					right: 0rem;
					bottom: 0.4rem;
					background-color: white;
					box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
					border-radius: $border-radius;
					z-index: -1;
				}
			}
		}
	}

	.ranking-paginator {
		:deep(.p-paginator) {
			padding: 0;
			justify-content: space-between;

			.p-paginator-page,
			.p-paginator-element {
				border-radius: $border-radius;

				&.p-highlight {
					background-color: $primary;
					color: white;
				}
			}

			.p-dropdown {
				background-color: $primary;
				box-shadow: none;
				border: none;

				.p-dropdown-label,
				.p-dropdown-trigger {
					color: white;
					outline: none;
				}
			}

			.left-section,
			.right-section {
				display: flex;
				align-items: center;
			}
		}
	}

	@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.ranking-paginator {
			:deep(.p-paginator) {
				padding: 1rem 0;

				.p-link {
					width: 2rem;
					min-width: 2rem;
					height: 2rem;
				}

				.left-section,
				.right-section {
					width: 100%;
					justify-content: center;
				}

				.left-section {
					margin-bottom: 1rem;
				}
			}
		}
	}
</style>
