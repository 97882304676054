<template>
	<div class="web">
		<Toast />
		<Loading />
		<Navbar class="d-none d-lg-block" />

		<router-link
			to="/"
			class="mobile-header position-sticky top-0 left-0 box-shadow w-100 d-flex justify-content-center d-lg-none mb-3 py-2"
		>
			<img src="@/assets/images/white-logo.png" alt="Logo" width="80" />
		</router-link>

		<div class="d-lg-none">
			<Sidebar />
			<SidebarButton />
		</div>

		<router-view v-slot="{ Component }">
			<transition name="slide" mode="out-in">
				<component :is="Component" :key="$route.path" class="mt-3 mt-md-5" />
			</transition>
		</router-view>

		<Banner class="mt-3 mt-md-5" />
		<Footer class="mt-3 mt-md-5" />
		<Stripe />
		<UpdatePasswordDialog />
	</div>
</template>

<script>
	// Panels
	import Navbar from "@/components/web/panels/Navbar.vue";
	import Sidebar from "@/components/web/panels/Sidebar.vue";
	import SidebarButton from "@/components/web/panels/SidebarButton.vue";
	import Banner from "@/components/web/panels/Banner.vue";
	import Footer from "@/components/web/panels/Footer.vue";
	import Loading from "@/components/partials/Loading.vue";
	import Stripe from "@/components/web/home/Stripe.vue";
	import UpdatePasswordDialog from "@/components/panels/UpdatePasswordDialog.vue";

	// Pages
	import Home from "@/components/web/home/Home.vue";
	import ChallengeDetails from "@/components/web/challenge/ChallengeDetails.vue";

	import { mapActions, mapState, mapMutations } from "vuex";

	import Toast from "primevue/toast";

	export default {
		components: {
			// Panels
			Navbar,
			Sidebar,
			SidebarButton,
			Banner,
			Footer,
			Loading,
			Stripe,
			UpdatePasswordDialog,

			// Pages
			Home,
			ChallengeDetails,

			Toast,
		},
		methods: {
			...mapActions(["setFormAccessToken"]),
			...mapMutations([
				"changeSuccessToast",
				"changeErrorToast",
				"changeWarningToast",
			]),
		},
		mounted() {
			this.setFormAccessToken();
		},
		computed: {
			...mapState([
				"errorToast",
				"successToast",
				"warningToast",
				"showSidebar",
				"userLogged",
			]),
		},
		watch: {
			successToast() {
				if (this.successToast != null) {
					this.$toast.add({
						severity: "success",
						summary: "Acción realizada",
						detail:
							this.successToast == true
								? "Operación realizada con éxito"
								: this.successToast,
						life: 3000,
					});
				}

				this.changeSuccessToast(null);
			},
			errorToast() {
				if (this.errorToast != null) {
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail:
							this.errorToast == true
								? "Ha ocurrido un error"
								: this.errorToast,
						life: 3000,
					});
				}

				this.changeErrorToast(null);
			},
			warningToast() {
				if (this.warningToast != null) {
					this.$toast.add({
						severity: "warn",
						summary: "Advertencia",
						detail:
							this.warningToast == true
								? "Ha ocurrido un error"
								: this.warningToast,
						life: 3000,
					});
				}

				this.changeWarningToast(null);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.mobile-header {
		background: linear-gradient(90deg, #1e0969 -8.93%, #9747ff 102.98%);
		z-index: 25;
	}
</style>
