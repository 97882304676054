<template>
	<div
		class="tab d-flex justify-content-center align-items-center p-2 p-md-3 w-100 overflow-hidden"
		:class="[
			active
				? 'bg-secondary text-white'
				: 'bg-light-grey text-grey reduced-height',
		]"
	>
		<div class="image">
			<img :src="imageFile" alt="Logo" />
		</div>

		<div class="text position-relative">
			{{ text }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			active: {
				type: Boolean,
				required: true,
			},
			text: {
				type: String,
				required: true,
			},
			image: {
				type: String,
				required: true,
			},
		},
		computed: {
			imageFile() {
				return require(`@/assets/images/web/${this.image}${
					this.active ? "-active" : ""
				}.png`);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.tab {
		position: relative;
		border-top-right-radius: $border-radius;
		border-top-left-radius: $border-radius;
		transition: $transition;

		&.reduced-height {
			height: 90%;
		}

		.image {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 15%;
			z-index: 1;
			transition: $transition;

			img {
				height: 100%;
				transition: $transition;
			}
		}

		.text {
			z-index: 2;
			transition: $transition;
		}

		&:hover {
			.image {
				left: 30%;

				img {
					transform: scale(1.2) rotateZ(-10deg);
				}
			}

			.text {
				transform: translateX(10px);
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.image {
				img {
					opacity: 0.5;
				}
			}
		}

		@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
			.image {
				img {
					opacity: 0.5;
				}
			}
		}
	}
</style>
