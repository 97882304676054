<template>
	<div
		class="auth d-flex flex-column justify-content-center align-items-center py-3 py-md-5 bg-primary"
	>
		<div class="spin-section">
			<div class="spin">
				<div class="ball ball-1"></div>
				<div class="ball ball-2"></div>
				<div class="ball ball-3"></div>
				<div class="ball ball-4"></div>
			</div>
		</div>

		<Toast />
		<Loading />

		<div
			class="logo d-flex justify-content-center cursor-pointer"
			@click="$router.push({ name: 'Inicio' })"
		>
			<img src="@/assets/images/black-logo.png" class="mb-3 mb-md-5" />
		</div>
		<div v-if="showForm == 'login'" class="form p-3 p-md-4 p-xxl-5">
			<LoginForm />
		</div>
		<div v-else-if="showForm == 'register'" class="form p-3 p-md-4 p-xxl-5">
			<RegisterForm />
		</div>
		<div v-else-if="showForm == 'forgot'" class="form p-3 p-md-4 p-xxl-5">
			<ForgotPasswordForm />
		</div>
		<div v-else class="form p-3 p-md-4 p-xxl-5">
			<ResetPasswordForm />
		</div>
	</div>
</template>

<script>
	import LoginForm from "@/components/auth/LoginForm.vue";
	import RegisterForm from "@/components/auth/RegisterForm.vue";
	import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm.vue";
	import ResetPasswordForm from "@/components/auth/ResetPasswordForm.vue";
	import Toast from "primevue/toast";
	import Loading from "@/components/partials/Loading.vue";

	export default {
		components: {
			LoginForm,
			RegisterForm,
			ForgotPasswordForm,
			ResetPasswordForm,
			Toast,
			Loading,
		},
		data: () => ({
			showForm: "login",
		}),
		methods: {
			showError() {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Ha ocurrido un error",
					life: 3000,
				});
			},
			showRegisterAlert() {
				this.$toast.add({
					severity: "success",
					summary: "Registro realizado",
					detail: "Se ha registrado correctamente",
					life: 3000,
				});
			},
			showSendForgotPasswordEmail() {
				this.$toast.add({
					severity: "success",
					summary: "Correo enviado",
					detail: "Se ha enviado el correo de recuperación de contraseña",
					life: 3000,
				});
			},
		},
		computed: {
			isPasswordResetRoute() {
				return this.$route.name === "PasswordReset";
			},
		},
		mounted() {
			if (this.isPasswordResetRoute) {
				this.showForm = "recover";
			}
		},
	};
</script>

<style lang="scss" scoped>
	.auth {
		position: relative;
		z-index: 1;
		min-height: 100vh;

		.image {
			position: absolute;
			z-index: 0;

			&.corner {
				right: 0;
				bottom: 0;
				width: 500px;
				max-width: 80vw;
			}

			&-1 {
				top: 0;
				left: 10%;
			}

			&-2 {
				top: 10rem;
				right: 0;
			}

			&-3 {
				top: 30rem;
				left: 5%;
			}

			&-4 {
				top: 35rem;
				left: 60%;
			}
		}

		.spin-section {
			position: fixed;
			height: 100vh;
			width: 100vw;

			.spin {
				position: absolute;
				z-index: 0;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				animation: rotate 40s infinite linear;
				height: 50rem;
				aspect-ratio: 1;

				@keyframes rotate {
					0% {
						transform: translate(-50%, -50%) rotate(0deg);
					}
					100% {
						transform: translate(-50%, -50%) rotate(360deg);
					}
				}

				.ball {
					aspect-ratio: 1;
					border-radius: 100%;
					filter: blur(150px);
					position: absolute;

					&-1 {
						height: 40rem;
						background-color: $light-primary;
						top: 0;
						left: 0;
						transform: translate(-50%, -50%);
					}

					&-2 {
						height: 40rem;
						background-color: $light-primary;
						bottom: 0;
						right: 0;
						transform: translate(50%, 50%);
					}

					&-3 {
						background-color: rgba($light-primary, 0.5);
						top: 0;
						right: 0;
						transform: translate(50%, -50%);
						height: 60rem;
					}

					&-4 {
						height: 60rem;
						background-color: rgba($light-primary, 0.5);
						bottom: 0;
						left: 0;
						transform: translate(-50%, 50%);
					}
				}
			}
		}

		.logo {
			z-index: 0;

			img {
				width: 200px;
				max-width: 40vw;
			}
		}

		.form {
			position: relative;
			z-index: 1;
			width: 90vw;
			max-width: 600px;
			border-radius: $border-radius;
			box-shadow: $smooth-box-shadow;
			background: rgba(white, 0.25);
			backdrop-filter: blur(4px);
			border: 1px solid white;

			.title {
				font-family: initial;
				font-weight: bold;
			}
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.logo {
				img {
					max-width: 50vw;
				}
			}
		}

		@media only screen and (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
			.image {
				width: 250px;
			}

			.logo {
				img {
					width: 200px;
				}
			}
		}
	}
</style>
