<template>
	<div
		v-if="userLogged != null"
		class="d-flex align-items-center gap-2 gap-md-3"
	>
		<div
			class="user-image d-flex align-items-center justify-content-center border-radius bg-white"
		>
			<img src="@/assets/images/web/user.png" alt="Logo" width="15" />
		</div>
		<span class="text-white">{{ userLogged.name }}</span>
	</div>
</template>

<script>
	import { mapState } from "vuex";

	export default {
		computed: {
			...mapState(["userLogged"]),
		},
	};
</script>

<style lang="scss" scoped>
	.user-image {
		width: 40px;
		height: 40px;
	}
</style>
