<template>
	<Dialog
		v-model:visible="$store.state.challenges_success.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@show="show"
		@hide="close"
	>
		<template #header>
			<FormTitle :title="challenge.name" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12 d-flex justify-content-center">
					<img src="@/assets/images/web/challenges-success.png" width="250" />
				</div>
				<div
					class="col-12 d-flex flex-column align-items-center justify-content-center"
				>
					<p class="text-dark fw-bold h5 mb-3">¡Enhorabuena!</p>
					<p class="text-dark fw-bold">Tu respuesta es correcta.</p>
					<div class="small text-uppercase text-dark fw-bold mt-3">
						Has ganado:
					</div>
					<div
						class="has-win bg-light-info text-info border-radius d-flex flex-column align-items-center justify-content-center mt-2"
					>
						<div class="has-win-title fw-bold d-flex align-items-center">
							<img src="@/assets/images/web/add.png" width="18" />
							{{ challengeDetails.points.points }}
						</div>
						<span class="fw-bold">Ptos.</span>
					</div>
					<p
						v-if="userLogged == null || !userLogged.checked"
						class="mt-3 text-secondary text-center small"
					>
						Si te ha gustado esta página y quieres contribuir económicamente a
						que se mantenga abierta, puedes usar la herramienta de “Comprar
						pistas” para ayudarnos. Muchísimas gracias
					</p>
					<div
						v-if="userLogged && !userLogged.checked"
						class="d-flex align-items-center small text-secondary"
					>
						<Checkbox inputId="checked" v-model="checked" :binary="true" />
						<label for="checked" class="ms-2"> No volver a mostrar </label>
					</div>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-center">
				<Button @click="close" label="Cerrar" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Checkbox from "primevue/checkbox";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Checkbox,
		},
		props: {
			challenge: {
				type: Object,
				default: () => ({}),
			},
		},
		data() {
			return {
				checked: false,
			};
		},
		computed: {
			...mapState(["dialogDefaults", "userLogged", "challengeDetails"]),
		},
		methods: {
			...mapActions(["updateChecked"]),
			...mapMutations(["toggleFormDialog", "setUserLoggedChecked"]),
			close() {
				if (this.checked) {
					this.updateChecked().then(() => {
						this.setUserLoggedChecked();
					});
				}

				this.toggleFormDialog({
					stateVariable: "challenges_success",
					show: false,
				});

				this.$router.push({
					name: "Inicio",
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.has-win {
		height: 5rem;
		width: 5rem;

		.has-win-title {
			font-size: 2.5rem;
			line-height: 2.5rem;

			img {
				transform: translateY(2px);
			}
		}
	}
</style>
