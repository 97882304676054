const mutations = {
	// General
	changeUserLogged(state, n) {
		state.userLogged = n;
	},
	changeUserLoggedName(state, n) {
		state.userLogged.name = n;
	},
	changeUserLoggedHints(state, n) {
		state.userLogged.hints = n;
	},
	toggleSidebar(state, n) {
		state.showSidebar = n;
	},
	toggleUpdatePassword(state, n) {
		state.showUpdatePassword = n;
	},
	toggleUpdatePasswordDialog(state, n) {
		state.updatePasswordDialog = n;
	},

	// Toasts
	changeSuccessToast(state, n) {
		state.successToast = n;
	},
	changeErrorToast(state, n) {
		state.errorToast = n;
	},
	changeWarningToast(state, n) {
		state.warningToast = n;
	},

	// Defaults
	changeCurrentTablePage(state, n) {
		state[n.stateVariable].currentPage = n.event.page;
		state[n.stateVariable].rows = n.event.rows;
	},
	changeCurrentTableSort(state, n) {
		state[n.stateVariable].sort.field = n.event.sortField;
		state[n.stateVariable].sort.order = n.event.sortOrder;
	},
	toggleFormDialog(state, n) {
		state[n.stateVariable].dialog = n.show;
	},
	changeFormDialogMode(state, n) {
		state[n.stateVariable].dialogMode = n.dialogMode;
	},
	changeCurrentRegister(state, n) {
		state[n.stateVariable].register = n.register;
	},

	// Users
	toggleInfoDialog(state, n) {
		state.users.infoDialog = n;
	},
	setUserLoggedUpdatedPassword(state) {
		state.userLogged.has_updated_password = true;
	},
	setUserLoggedChecked(state) {
		state.userLogged.checked = true;
	},

	// Challenges
	clearChallengesList(state) {
		state.challengesList = [];
	},
	toggleShowHints(state, n) {
		state.challenges.showHints = n;
	},
	toggleAllHintsSolved(state, n) {
		state.allHintsSolved = n;
	},
	changeBuyHintsQuantity(state, n) {
		state.buy_hints.quantity = n;
	},
	changeBuyHintsOrderHash(state, n) {
		state.buy_hints.orderHash = n;
	}
};

export default mutations;
