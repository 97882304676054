<template>
	<GoogleLogin :callback="callback" prompt auto-login />
</template>
<script>
	import { mapActions } from "vuex";
	import { decodeCredential } from "vue3-google-login";

	export default {
		methods: {
			...mapActions(["checkSocialLogin"]),
			callback(response) {
				const userData = decodeCredential(response.credential);

				this.checkSocialLogin({
					token: response.credential,
					email: userData.email,
					name: userData.name,
				});
			},
		},
	};
</script>
