<template>
	<div>
		<ChallengesTable
			v-if="!challenges.showHints"
			:route="route"
			:stateVariable="stateVariable"
		/>
		<ChallengesForm :route="route" :stateVariable="stateVariable" />
		<CategoryForm :route="'categories'" :stateVariable="'categories'" />

		<!-- Hints -->
		<Hints v-if="challenges.showHints" />
	</div>
</template>

<script>
	import ChallengesTable from "./ChallengesTable.vue";
	import ChallengesForm from "./ChallengesForm.vue";
	import CategoryForm from "./CategoryForm.vue";
	import Hints from "./hints/Hints.vue";

	import { mapState } from "vuex";

	export default {
		components: {
			ChallengesTable,
			ChallengesForm,
			CategoryForm,
			Hints,
		},
		data() {
			return {
				route: "challenges",
				stateVariable: "challenges",
			};
		},
		computed: {
			...mapState(["challenges"]),
		},
	};
</script>
