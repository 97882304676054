<template>
	<Button
		class="sidebar-button box-shadow"
		icon="pi pi-bars"
		severity="help"
		text
		raised
		rounded
		aria-label="Favorite"
		@click="toggleSidebar(true)"
	/>
</template>

<script>
	import { mapMutations } from "vuex";

	export default {
		methods: {
			...mapMutations(["toggleSidebar"]),
		},
	};
</script>

<style lang="scss" scoped>
	.sidebar-button {
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		z-index: 5;
	}
</style>
