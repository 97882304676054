<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="challenges.list"
		:total="challenges.listTotal"
		:filters="filters"
		:delete="'challenges'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir reto'"
	>
		<template #custom-actions>
			<Button
				v-if="$helper.checkUserHasPermission('categories', 'create')"
				:label="'Añadir categoría'"
				class="bg-yellow border-0 text-white"
				@click="addCategory()"
			/>
		</template>
		<template #columns>
			<Column header="Nº" field="id"></Column>
			<Column header="Nombre" field="name" sortable>
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<div
							v-if="slotProps.data.image != null"
							class="image"
							:style="{ backgroundImage: `url(${slotProps.data.image})` }"
						></div>
						<div
							v-else
							class="image"
							:style="{
								backgroundImage: `url(${noImage})`,
							}"
						></div>

						<span class="text-dark fw-bold">{{ slotProps.data.name }}</span>
					</div>
				</template>
			</Column>
			<Column header="Nivel">
				<template #body="slotProps">
					<div class="d-flex">
						<Level :name="slotProps.data.level" />
					</div>
				</template>
			</Column>
			<Column header="Categoría" field="category.name"></Column>
			<Column header="Fecha">
				<template #body="slotProps">
					<div v-if="slotProps.data.date">
						{{ $helper.formatDate(slotProps.data.date, "DD/MM/YYYY") }}
					</div>
					<div v-else>-</div>
				</template>
			</Column>
			<Column header="Acciones">
				<template #body="slotProps">
					<Button class="table-custom-button-action d-flex gap-3">
						<img
							src="@/assets/images/icons/publish.png"
							@click="publish(slotProps.data.id)"
							:class="[slotProps.data.published ? '' : 'not-active']"
							v-tooltip.top="
								slotProps.data.published ? 'Desactivar' : 'Activar'
							"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'update')"
							src="@/assets/images/icons/edit.png"
							@click="showRegister(slotProps.data, 'edit')"
						/>

						<img
							src="@/assets/images/icons/hint.png"
							@click="showHints(slotProps.data)"
							v-tooltip.top="'Mostrar pistas'"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/delete.png"
							@click="deleteRegister(slotProps.data.id)"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	import Level from "../partials/Level.vue";

	export default {
		components: {
			Column,
			Level,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "name",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "level",
						value: null,
						type: "dropdown",
						placeholder: "Nivel",
						options: [
							{
								label: "Fácil",
								value: "easy",
							},
							{
								label: "Medio",
								value: "medium",
							},
							{
								label: "Difícil",
								value: "hard",
							},
						],
					},
					{
						name: "category",
						value: null,
						type: "string",
						placeholder: "Categoría",
					},
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList", "publishChallenge"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleShowHints",
			]),
			addCategory() {
				this.toggleFormDialog({
					stateVariable: "categories",
					show: true,
				});
			},
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.changeFormDialogMode({
						stateVariable: this.stateVariable,
						dialogMode: type,
					});
				}
			},
			deleteRegister(id) {
				this.$confirm.require({
					message: "¿Desea borrar el registro?",
					header: "Borrar registro",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/${this.route}/${id}`,
						}).then(() => {
							this.$refs.table.getList();
						});
					},
				});
			},
			publish(id) {
				this.publishChallenge({ id }).then(() => {
					this.$refs.table.getList();
				});
			},
			showHints(challenge) {
				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: challenge,
				});

				this.$nextTick(() => {
					this.toggleShowHints(true);
				});
			},
		},
		computed: {
			...mapState(["challenges"]),
			noImage() {
				return require("@/assets/images/no-image.png");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.image {
		height: 60px;
		width: 90px;
		background-position: center;
		background-size: cover;
		border-radius: $border-radius;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
