<template>
	<Dialog
		v-model:visible="$store.state.hints.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12">
					<TextArea
						ref="statement"
						label="Enunciado"
						:disabled="disabled"
						:error="form.errors.get('statement')"
						@change-value="(value) => (form.statement = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="solution"
						label="Solución"
						:disabled="disabled"
						:error="form.errors.get('solution')"
						@change-value="(value) => (form.solution = value)"
					/>
					<div class="small">
						Si quieres tener varias soluciones posibles, debes separarlas por el
						símbolo "@"
					</div>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							show: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border border-primary text-primary"
				/>
				<Button @click="save()" label="Guardar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
			challengeId: {
				type: Number,
				required: false,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "pista",
			title: `Añadir pista`,
			disabled: false,
			lastPageChange: null,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.hints.register != null;
				const url = `/hints${update ? `/${this.hints.register.id}` : ""}`;

				if (!this.isProfileRoute) {
					this.form.challenge_id = this.challenges.register.id;
				} else {
					this.form.challenge_id = this.challengeId;
				}

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							show: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.hints.currentPage,
							rows: this.hints.rows,
							additionalParams: {
								challenge_id: this.challenges.register.id,
							},
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.hints.register;

				if (register != null) {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}

					if (this.hints.dialogMode == "edit") {
						this.title = `Editar ${this.modelName}`;
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "hints", "challenges"]),
			isProfileRoute() {
				return this.$route.name == "Perfil";
			},
		},
	};
</script>
