<template>
	<div class="container">
		<h3 class="fw-bold">Histórico de puntos conseguidos</h3>
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="d-flex options bg-white">
					<div
						v-for="(option, index) in options"
						:key="index"
						:value="option.value"
						class="option px-2 py-1 cursor-pointer"
						:class="{ selected: option.selected }"
						@click="selectOption(index)"
					>
						{{ option.value }}
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<div
					class="d-flex flex-column align-items-start align-items-md-end gap-1"
				>
					<div class="d-flex align-items-center gap-1">
						<div class="circle bg-blue"></div>
						<div class="text-muted small">Puntos conseguidos</div>
					</div>
					<div class="d-flex align-items-center gap-1">
						<div class="circle bg-warning"></div>
						<div class="text-muted small">Pistas utilizadas</div>
					</div>
				</div>
			</div>
			<div v-if="pointsHistoric != null" class="col-12 mt-3">
				<Chart
					ref="Chart"
					:pointsHistoric="pointsHistoric"
					:filter="selectedOption.value"
					@update:totalPoints="totalPoints = $event"
					@update:totalHints="totalHints = $event"
				/>
			</div>
			<div class="col-12 mt-3">
				<div class="d-flex flex-column gap-1">
					<div class="d-flex align-items-center gap-1">
						<div class="rectangle bg-blue"></div>
						<div class="text-muted small">Total: {{ totalPoints }}</div>
					</div>
					<div class="d-flex align-items-center gap-1">
						<div class="rectangle bg-warning"></div>
						<div class="text-muted small">Total: {{ totalHints }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Chart from "@/components/web/profile/Chart.vue";
	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Chart,
		},
		data: () => ({
			options: [
				{ value: "24 H", selected: true },
				{ value: "7 D", selected: false },
				{ value: "2 S", selected: false },
				{ value: "3 S", selected: false },
				{ value: "1 M", selected: false },
				{ value: "3 M", selected: false },
				{ value: "1 A", selected: false },
				{ value: "TODO", selected: false },
			],
			totalPoints: null,
			totalHints: null,
		}),
		methods: {
			...mapActions(["getPointsHistoric"]),
			selectOption(index) {
				this.options.forEach((option, i) => {
					if (i === index) {
						option.selected = true;
					} else {
						option.selected = false;
					}
				});

				this.getHistoric();
			},
			getHistoric() {
				this.getPointsHistoric({
					filter: this.selectedOption.value,
				}).then(() => {
					this.$refs.Chart.renderChart();
				});
			},
		},
		mounted() {
			this.getHistoric();
		},
		computed: {
			...mapState(["pointsHistoric"]),
			selectedOption() {
				return this.options.find((option) => option.selected);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.options {
		border: 1px solid $primary;
		border-radius: 10px;
		width: fit-content;
		overflow: hidden;

		.option {
			border-right: 1px solid $primary;

			&.selected {
				background-color: $primary;
				color: $white;
			}

			&:last-of-type {
				border-right: none;
			}

			&:not(.selected):hover {
				background-color: rgba($primary, 0.3);
				transition: $transition;
			}
		}

		@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			.option {
				font-size: 0.8rem;
			}
		}

		@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
			.option {
				font-size: 0.8rem;
			}
		}
	}

	.bg-blue {
		background-color: #0e9cff;
	}

	.circle {
		width: 5px;
		height: 5px;
		border-radius: 50%;
	}

	.rectangle {
		width: 16px;
		height: 8px;
	}
</style>
