<template>
	<div
		v-if="ranking.topThree != null"
		class="w-100 d-flex justify-content-center"
	>
		<div class="d-flex gap-3">
			<PodiumStep
				v-if="ranking.topThree.length >= 2"
				:name="ranking.topThree[1].user.name"
				:points="ranking.topThree[1].points"
				:step="2"
			/>
			<PodiumStep
				v-if="ranking.topThree.length >= 1"
				:name="ranking.topThree[0].user.name"
				:points="ranking.topThree[0].points"
				:step="1"
			/>
			<PodiumStep
				v-if="ranking.topThree.length >= 3"
				:name="ranking.topThree[2].user.name"
				:points="ranking.topThree[2].points"
				:step="3"
			/>
		</div>
	</div>
</template>

<script>
	import PodiumStep from "@/components/web/ranking/PodiumStep.vue";
	import { mapState } from "vuex";

	export default {
		components: {
			PodiumStep,
		},
		computed: {
			...mapState(["ranking"]),
		},
	};
</script>

<style lang="scss" scoped>
	.banner {
		background-image: url("@/assets/images/web/question-icons.png");
		background-position: center;
		background-size: cover;
		border-radius: 20px;

		.challenges-icon {
			width: 7rem;
			border-radius: 20px;
			aspect-ratio: 1;
		}
	}
</style>
