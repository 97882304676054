<template>
	<div
		class="d-flex justify-content-center p-3 align-items-center border-radius text-center"
		:class="theme"
	>
		<h5 class="mb-0 fw-bold">{{ title }}</h5>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				required: true,
			},
			theme: {
				type: String,
				default: "blue",
			},
		},
	};
</script>

<style lang="scss" scoped>
	.blue {
		border: 1px solid $info;
		background-color: $light-info;

		h5 {
			color: $info;
		}
	}

	.yellow {
		border: 1px solid $warning;
		background-color: $light-warning;

		h5 {
			color: $warning;
		}
	}

	.green {
		border: 1px solid $success;
		background-color: $light-success;

		h5 {
			color: $success;
		}
	}

	.red {
		border: 1px solid $danger;
		background-color: $light-danger;

		h5 {
			color: $danger;
		}
	}
</style>
