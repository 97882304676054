<template>
	<div>
		<CategoriesTable :route="route" :stateVariable="stateVariable" />
		<CategoriesForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import CategoriesTable from "./CategoriesTable.vue";
	import CategoriesForm from "./CategoriesForm.vue";

	import { mapState } from "vuex";

	export default {
		components: {
			CategoriesTable,
			CategoriesForm,
		},
		data() {
			return {
				route: "categories",
				stateVariable: "categories",
			};
		},
		computed: {
			...mapState(["categories"]),
		},
	};
</script>
